import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef, RefObject } from "react";
import { EmojiData } from "emoji-mart";
import { BaseMessage, CometChat } from "@cometchat/chat-sdk-javascript";
import { blockUsers, CUSTOM_MESSAGE_TYPE, TYPE_CONVERSATION, getMessageFromConversation, sendCustomMessage, startTypingMessage, stopTypingMessage, TTypeConversation, unblockUsers, CUSTOM_MESSAGE_DATA_TYPE, leaveGroup, IConversation, updateMessageTemplates, IReaction } from "../../../components/src/CometChat";
import { CometChatMessageTemplate, CometChatUIKit } from "@cometchat/chat-uikit-react";
import { emitterEvents, handleConditionFunction } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  checked?: boolean,
  onSendMessage: (message: string) => void
  onSendMediaMessage: (file: File) => void
  conversation: CometChat.User | CometChat.Group;
  onCloseSelectedConversation: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  template: CometChatMessageTemplate[];
  message: string;
  listMessage: BaseMessage[];
  emojiAnchorEl: null | HTMLElement;
  isTypingMessage: boolean;
  loginUser: CometChat.User | null;
  idReceiverMessage: string;
  listFileUpload: File[];
  muteNotificationsModal: boolean;
  disappearingNotificationModal: boolean;
  blockUserModal: boolean;
  clearChatModal: boolean;
  isBlockedByMe: boolean;
  hasBlockByMe: boolean;
  isOpenModalTotalReaction: boolean;
  listReaction: IReaction[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  typingMessageListenerId: string = "Typing_Message_Listener_Id";
  reactionMessageListenerId: string = "Reaction_Message_Listener_Id";
  fileInputRef: RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      template: [],
      message: "",
      listMessage: [],
      emojiAnchorEl: null,
      isTypingMessage: false,
      loginUser: null,
      idReceiverMessage: "",
      listFileUpload: [],
      muteNotificationsModal: false,
      disappearingNotificationModal: false,
      blockUserModal: false,
      clearChatModal: false,
      isBlockedByMe: false,
      hasBlockByMe: false,
      isOpenModalTotalReaction: false,
      listReaction: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.fileInputRef = createRef()
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const { blockedByMe = false } = this.props.conversation as unknown as IConversation;
    this.handleListenTypingMessage();
    await this.getIdReceiverConversaion();
    await this.setUserLoginInformation();

    CometChat.addMessageListener(this.reactionMessageListenerId, {
      onMessageReactionAdded: async (message: { reaction: { messageId: string } }) => {
        emitterEvents.emit('messageIdReaction', message.reaction.messageId);
      },
      onMessageReactionRemoved: async (message: { reaction: { messageId: string } }) => {
        emitterEvents.emit('messageIdReaction', message.reaction.messageId);
      }
    })

    const definedTemplates = updateMessageTemplates({
      userInfo: this.state.loginUser,
      checked: this.props.checked,
      isBlockedByMe: this.state.isBlockedByMe,
      onOpenModalTotalReaction: this.handleOpenModalTotalReaction
    })
    this.setState({ template: definedTemplates, isBlockedByMe: blockedByMe });
  }

  setUserLoginInformation = async () => {
    const userInformation = await CometChatUIKit.getLoggedinUser();
    this.setState({ loginUser: userInformation });
  }

  setListMessage = async (idType: string, type: TTypeConversation) => {
    const listMessageUser = await getMessageFromConversation(idType, type);
    this.setState({ listMessage: listMessageUser })
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    // Check if the 'data' prop has changed
    if (prevProps.conversation !== this.props.conversation) {
      this.getIdReceiverConversaion();
      this.setState({
        message: ""
      });
    }
  }

  async componentWillUnmount() {
    CometChat.removeMessageListener(this.typingMessageListenerId)
    CometChat.removeMessageListener(this.reactionMessageListenerId)
  }

  getIdReceiverConversaion = async () => {
    const { conversation } = this.props;
    const { uid = "", guid = "" } = conversation as unknown as { uid?: string, guid?: string };
    const typeConversation = guid ? TYPE_CONVERSATION.GROUP : TYPE_CONVERSATION.USER
    await this.setListMessage(uid || guid, typeConversation);
    this.setState({ idReceiverMessage: uid })
  }

  handleListenTypingMessage = () => {
    CometChat.addMessageListener(
      this.typingMessageListenerId,
      new CometChat.MessageListener({
        onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
          const senderMessageUid = typingIndicator.getSender().getUid();
          if (senderMessageUid !== this.state.idReceiverMessage) return;
          this.setState({ isTypingMessage: true });
        },
        onTypingEnded: () => {
          this.setState({ isTypingMessage: false });
        }
      })
    );
  }

  handleExitGroup = async (guid: string) => {
    await leaveGroup(guid);
    this.props.onCloseSelectedConversation();
  }

  handleMessageChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ message: event.target.value });
    await startTypingMessage(this.props.conversation);
  };

  handleBlurMessage = () => {
    stopTypingMessage(this.props.conversation);
  }

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  };

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  };

  handleSelect = (emoji: EmojiData) => {
    this.setState({ message: this.state.message + (emoji as any).native })
  }

  handleClickSendMessage = async () => {
    if (this.state.message)
      this.props.onSendMessage(this.state.message);
    if (!!this.state.listFileUpload.length) {
      // Get only one file
      const file = this.state.listFileUpload[0];
      this.props.onSendMediaMessage(file);
    }
    this.setState({ message: '', listFileUpload: [] })
  }

  handleSelectFileIcon = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current?.click();
    }
  }

  handleSelectFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (this.state.listFileUpload.length > 0) return;
      const castFiles = files[0];
      const isFileSelected = this.state.listFileUpload.some(fileUpload => fileUpload.name === castFiles.name);
      if (!isFileSelected)
        this.setState({ listFileUpload: [...this.state.listFileUpload, castFiles] });
    }
  }

  handleRemoveFileUpload = (fileName: string) => {
    const filesSelectedWithoutFileName = this.state.listFileUpload.filter(fileUpload => fileUpload.name !== fileName);
    this.setState({ listFileUpload: filesSelectedWithoutFileName });
  }

  handleToggleMuteModal = () => {
    this.setState({ muteNotificationsModal: !this.state.muteNotificationsModal })
  }

  handleToggleDisappearingModal = () => {
    this.setState({ disappearingNotificationModal: !this.state.disappearingNotificationModal })
  }

  handleToggleBlockModal = () => {
    this.setState({ blockUserModal: !this.state.blockUserModal })
  }

  handleBlockUser = async (receiverUID: string) => {
    const { isBlockedByMe } = this.state
    const messageAction = handleConditionFunction(isBlockedByMe, "You unblock this Person.", "You block this Person tap to unblock");
    const messageType = handleConditionFunction(isBlockedByMe, CUSTOM_MESSAGE_DATA_TYPE.UNBLOCK, CUSTOM_MESSAGE_DATA_TYPE.BLOCK)
    const customData = {
      message: messageAction,
      type: messageType
    }
    const customType = CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION;
    if (!isBlockedByMe) {
      await sendCustomMessage(receiverUID, customData, customType);
      await blockUsers([receiverUID]);
    }
    else {
      await unblockUsers([receiverUID]);
      await sendCustomMessage(receiverUID, customData, customType);
    }
    this.setState({ blockUserModal: !this.state.blockUserModal, isBlockedByMe: !isBlockedByMe })
  }

  handleToggleClearChatModal = () => {
    this.setState({ clearChatModal: !this.state.clearChatModal })
  }

  handleOpenModalTotalReaction = (listReaction: IReaction[]) => {
    this.setState({ isOpenModalTotalReaction: true, listReaction });
  }

  handleCloseModalTotalReaction = () => {
    this.setState({ isOpenModalTotalReaction: false });
  }
  // Customizable Area End
}
