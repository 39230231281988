Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint=
"bx_block_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.mySubscription = "My Subscription";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.subscriptionHeading='Choose the right subscription for you!';
exports.subscriptionDescription='Individual Creators will be eligible to receive 50% of the advertising revenue generated by their content.';
exports.submitSubscriptionAPiMethod='POST';
exports.submitSubscriptionApiEndPoint='bx_block_subscriptions/user_subscribe';
exports.updateSubscriptionApiEndPoint='bx_block_subscriptions/user_subscriptions';
exports.noData='Data not found';
exports.getOwnerDetailsAPiEndPoint='bx_block_custom_user_subs/ownership_page_info';
exports.getOwnershipApiEndpoint='bx_block_custom_user_subs/ownerships?sort_order=asc&sort_column=price';
exports.skip='skip';
exports.next='next';
exports.submitPriceEndPoint='bx_block_custom_user_subs/user_ownerships';
exports.sharePrice='Share Price';
exports.skipNow='Skip for Now';
exports.getUserInfo="account_block/accounts/logged_user"; 
exports.patchTypeApi="PATCH";
exports.uploadPictureApiEndPoint='account_block/update_profile';
exports.uploadPictureApiMethod='PATCH';
// Customizable Area End
