import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  subscriptionType: string;
  isAutoRenewal: boolean;
  isFormOpen: boolean;
  updateId: number | null;
  token: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomaticRenewalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAutomaticRenewalsCallId: string = "";
  createAutomaticRenewalsCallId: string = "";
  updateAutomaticRenewalsCallId: string = "";

  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      subscriptionType: "",
      isAutoRenewal: false,
      isFormOpen: false,
      updateId: null,
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (
      this.getAutomaticRenewalsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    }
    if (
      this.createAutomaticRenewalsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.showAlert("", configJSON.successMessage);
      this.getAutomaticRenewals();
      this.onCancelHandler();
    }
    if (
      this.updateAutomaticRenewalsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.showAlert("", configJSON.updateMessage);
      this.getAutomaticRenewals();
      this.onCancelHandler();
    }
    // Customizable Area End
  }

  getAutomaticRenewals() {
    const getAutomaticRenewalsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutomaticRenewalsCallId = getAutomaticRenewalsMsg.messageId;

    getAutomaticRenewalsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.automaticRenewalEndpoint
    );

    getAutomaticRenewalsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.automaticRenewalsApiContentType,
        token: this.state.token,
      })
    );

    getAutomaticRenewalsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.automaticRenewalsListApiMethod
    );

    runEngine.sendMessage(getAutomaticRenewalsMsg.id, getAutomaticRenewalsMsg);
  }

  createAutoRenewal() {
    if (this.state.subscriptionType.length < 1) {
      this.showAlert("", "Please enter subscription type!");
    } else {
      const httpBody = {
        auto_renew: {
          subscription_type: this.state.subscriptionType,
          is_auto_renewal: this.state.isAutoRenewal,
        },
      };

      const createAutomaticRenewalsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAutomaticRenewalsCallId = createAutomaticRenewalsMsg.messageId;

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.automaticRenewalEndpoint
      );

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.automaticRenewalsApiContentType,
          token: this.state.token,
        })
      );

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.automaticRenewalsCreateApiMethod
      );

      runEngine.sendMessage(
        createAutomaticRenewalsMsg.id,
        createAutomaticRenewalsMsg
      );
    }
  }
  onCancelHandler() {
    this.setState({
      isFormOpen: false,
      subscriptionType: "",
      isAutoRenewal: false,
      updateId: null,
    });
  }
  onUpdateHandler(item: any) {
    this.setState({
      subscriptionType: item.attributes.subscription_type,
      isAutoRenewal: item.attributes.is_auto_renewal,
      isFormOpen: true,
      updateId: item.attributes.id,
    });
  }

  updateAutoRenewal() {
    if (this.state.subscriptionType.length < 1) {
      this.showAlert("", "Please enter subscription type!");
    } else {
      const httpBody = {
        auto_renew: {
          subscription_type: this.state.subscriptionType,
          is_auto_renewal: this.state.isAutoRenewal,
        },
      };

      const updateAutomaticRenewalsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateAutomaticRenewalsCallId = updateAutomaticRenewalsMsg.messageId;

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.automaticRenewalEndpoint}/${this.state.updateId}`
      );

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.automaticRenewalsApiContentType,
          token: this.state.token,
        })
      );

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.automaticRenewalsUpdateApiMethod
      );

      runEngine.sendMessage(
        updateAutomaticRenewalsMsg.id,
        updateAutomaticRenewalsMsg
      );
    }
  }
  // Customizable Area End
}
