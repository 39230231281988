import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface IUserData{
  dateOfBirth: string;
  name: string;
  phoneNumber: string;
  email: string;
}
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface ResponseJson{
  errors?: Array<string | {}>;
  messages?: [{}]| [string];
  data?: {}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  checked?:boolean;
  renderBlock: (route: string) => void
  // Customizable Area End
}

interface S {  
  // Customizable Area Start
  isLoading: boolean;
  renderAccountSettingsType: string;
  passwordVerified: boolean;
  selectedType: string;
  userData: IUserData;
  editUserType: string;
  editUserTypeValue: string;
  messageType: string;
  message: string;
  logoutModal: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  pasError: string;
  deactivatePass: string;
  updateError: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validatePasswordId:string ="";
  getAccountDataId: string ="";
  deactivateApiId: string = "";
  updateNewPswrdId: string = "";
  updateUserNameId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      renderAccountSettingsType: "default",
      passwordVerified: false,
      selectedType: "default",
      userData: {} as IUserData,
      editUserType: "",
      editUserTypeValue: "",
      messageType: "",
      message: "",
      logoutModal: false,
      showPassword: false,
      showConfirmPassword: false,
      pasError: "",
      deactivatePass: "",
      updateError: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.validatePasswordId) {
          this.validateAPiResponse(responseJson)
        }
        if(apiRequestCallId === this.getAccountDataId){
          if(responseJson.data){
            const data = responseJson.data.attributes
            this.setState({
              userData: {
                name: data.user_name,
                dateOfBirth: data.date_of_birth,
                phoneNumber: data.full_phone_number,
                email: data.email
              },
              isLoading: false
            })
          }
        }
        if(apiRequestCallId === this.deactivateApiId){
          this.onDeactivateSuc(responseJson)
        }
        if(apiRequestCallId === this.updateNewPswrdId){
          this.onUpdatePswdResponse(responseJson)
        }
        if(apiRequestCallId === this.updateUserNameId){
          this.onUpdateUSerNameApiSuc(responseJson)
        }
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }
  }
  onUpdateUSerNameApiSuc = (responseJson: ResponseJson) => {
    if(responseJson.data){
      this.setMessage("suc", "Your username has been updated successfully");
      setTimeout(() => {
        this.setState({
          messageType: "",
          message: ""
        })
        this.onClickBackButton();
      },2000)
    }
    if(responseJson.errors && typeof responseJson.errors[0] === 'string'){
      this.setState({
        updateError: responseJson.errors[0]
      })
    }
  }
  onUpdatePswdResponse = (responseJson: ResponseJson) => {
    if(responseJson.errors && typeof responseJson.errors[0] === 'string'){
      this.setState({
        pasError:  responseJson.errors[0]
      });
    }
    if(responseJson.data){
      this.setMessage("suc", "Your Password has been updated successfully");
      setTimeout(() => {
        this.setState({
          messageType: "",
          message: ""
        })
        this.onClickBackButton();
      },2000)
     
    }
  }

  onDeactivateSuc = (responseJson: ResponseJson) => {
    if(responseJson.messages && typeof responseJson.messages[0] === 'string'){
      setTimeout(() => {
        localStorage.removeItem("v");
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo");
        this.props.renderBlock("AccountDeactivated");
      },200)
     
    }
  }
  onVerifyPasswordChang = () => {
    this.setState({
      pasError: "" 
    });
  }
  validateAPiResponse = (responseJson: ResponseJson) => {
    if(responseJson.errors){
      this.setState({
        pasError: "Incorrect Password" 
      });
    }else if(responseJson.messages && responseJson.messages[0] === "Verified"){
      
      if(this.state.renderAccountSettingsType == "verifyPassword"){
        localStorage.setItem("v","y");
        const slectedType = this.state.selectedType
        this.setState({
          renderAccountSettingsType: slectedType,
        })
      }else if(this.state.renderAccountSettingsType == "deactivateAccount"){
        this.setState({
          logoutModal: true
        })
      }
    }
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setMessage = (type:string, msg:string) => {
    this.setState({
      messageType: type,
      message: msg
    })
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.renderAccountSettingsType !== prevState.renderAccountSettingsType) {
      if (this.state.renderAccountSettingsType === "accountInformation") {
         
        this.setState({
          isLoading: true
        })
        this.getAccountData()
      }
    }
  }

  getAccountData = () => {
    this.getAccountDataId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "account_block/accounts/logged_user",
    });
  }

  validatePassword = (values: {password: string}) => {
    if (!values?.password){
      this.setState({
        pasError: "Please Enter password"
      })
      return
    }
    this.validatePasswordId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.validatePasswordApi,
      body: {
        password:  values?.password
      }
    });
    if(this.state.renderAccountSettingsType === "deactivateAccount"){
      this.setState({
        deactivatePass: values?.password
      })
    }
  }
  onClickVisiblePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  onClickConfirmVisible = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    })
  }
  onCloseLogout = () => {
    this.setState({
      logoutModal:false
    })
  }
  onLogout = () => {
    if(this.state.renderAccountSettingsType === "accountInformation"){
      localStorage.removeItem("v");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
      this.props.renderBlock("Home");
      this.onCloseLogout()
    }else{
      this.setState({        
        logoutModal:false
      })
      this.deactivateApiId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPostMethod,
        endPoint: "account_block/accounts/deactivate",
        body: {
          password: this.state.deactivatePass
        }
      });
    }

  }
  renderBackButtonTitle = () => {
    switch (this.state.renderAccountSettingsType) {
      case "verifyPassword":
        return "Verify Your Password";
      case "accountInformation":
        return "Account Information";
      case "deactivateAccount":
        return "Confirm your password";
      case "changePassword":
        return "Change Password"
    }     
  }
  onClickBackButton = async () => {
    let backToType = "default";
    switch (this.state.renderAccountSettingsType) {
      case "verifyPassword":
        // No change needed, as `backToType` is already "default"
        break;
  
      case "userEditForm":
        backToType = "accountInformation";
        break;
  
      default:
        break;
    }
  
    this.setState({
      messageType: "",
      message: "",
      pasError: "",
      renderAccountSettingsType: backToType,
      updateError: ""
    });
  };

  changeAccountSettingsRenderType = (type: string) => {
    const isVerified = localStorage.getItem("v");
    this.setState({
      renderAccountSettingsType: isVerified === "y" ? type : "verifyPassword",
      selectedType: type,
    })
  }

  updateNewPswrd = (values: { current_password:string, new_password:string, confirm_password:string}) => {
    this.updateNewPswrdId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiPatchMethod,
      endPoint: "account_block/accounts/change_password",
      body: values
    });
  }
  redirectToEditForm = (editType: string, value: string) => {
    this.setState({
      renderAccountSettingsType: "userEditForm",
      editUserType: editType,
      editUserTypeValue: value
    })
  }

  updateUserName = (val: string) => {
    if(val){
      this.updateUserNameId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPatchMethod,
        endPoint: "account_block/update_profile",
        body: {
          user_name: `@${val}`
        }
      });
    }else{
      this.setState({
        updateError: "User Name shouldnot be Empty"
      })
    }
  }

  updateErrorMsg = (msg: string) => {
    this.setState({
      updateError: msg
    })
  }
  // Customizable Area End
}
