// Customizable Area Start
import React, { Fragment } from "react";

import GroupsController, {
  Props,
} from "./GroupsController";
import { Box, createStyles, Typography, withStyles, Input, InputAdornment, Modal, styled, Checkbox, IconButton } from "@material-ui/core";
import { backArrowDark, backArrowWhite, cameraIcon, checkedCheckBox, profile, rightBlackIcon, rightIcon, uncheckedCheckBox } from "./assets";
import { searchWhite } from "../../chat/src/assets";
import { CloseOutlined } from "@material-ui/icons";
import GroupsPermission from "./GroupsPermission.web";
import clsx from "clsx";
import EmojiPicker from "../../../components/src/EmojiPicker.web";
import DisappearingMessagesModal, { DISAPPEARING_MESSAGES_SETTINGS } from "../../../components/src/Chats/DisappearingMessagesModal.web";

interface IPropContactList {
  title: string;
  contacts: {
    getUid: () => string;
    getAvatar: () => string | null;
    getName: () => string;
  }[];
  checkedContacts: { [uid: string]: boolean };
  onCheckboxChange: (uid: string) => void;
}

export class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
  }

  renderEmotionPicker = () => {
    const { emojiAnchorEl } = this.state;

    return (
      <EmojiPicker
        onClickEmoji={this.handleClickEmoji}
        onSelectEmoji={this.handleSelectEmoji}
        onCloseEmoji={this.handleCloseEmoji}
        emojiAnchorEl={emojiAnchorEl}
      />
    )
  }

  renderDisapearingMessages = () => {
    const { isOpenModalDisappearing } = this.state;
    const { checked } = this.props
    return (
      <DisappearingMessagesModal
        data-testid="messagesModal"
        isOpen={isOpenModalDisappearing}
        value="0" checked={!checked}
        onSubmit={this.handleSubmitDisappearingMessage}
        onClose={() => this.handleToggleModalDisappearing(isOpenModalDisappearing)} />
    )
  }

  renderContactList = ({ title, contacts, checkedContacts, onCheckboxChange }: IPropContactList) => {
    const { classes } = this.props
    return (
      <Box className={classes.suggestPeopleContainer}>
        <Typography className={classes.suggestPeopleTitle}>{title}</Typography>
        <Box className="listContactUser">
          {contacts.map(contact => {
            const uid = contact.getUid();
            const isChecked = checkedContacts[uid] || false;

            return (
              <Box key={uid} className="contactUser">
                <Box className="contactUserInformation">
                  <img src={contact.getAvatar() || profile} width={48} height={48} alt="i" style={{ borderRadius: "30px" }} />
                  <Typography component="p">{contact.getName()}</Typography>
                </Box>
                <Checkbox
                  style={{ width: "20px", height: "20px" }}
                  checked={isChecked}
                  data-test-id="checkBoxSelectedMember"
                  onChange={() => onCheckboxChange(uid)}
                  icon={<img src={uncheckedCheckBox} alt="uncheck" />}
                  checkedIcon={<img src={checkedCheckBox} alt="checked" />}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    )
  };

  renderListMemberSelected = (uid: string) => {
    const contact = [...this.state.listFrequentlyContact, ...this.state.listContact].find(contact => contact.getUid() === uid);
    return <Box className="memberContainer" key={uid}>
      <Box className="memberSelected">
        <Box className="memberSelected__profile" >
          <img src={contact?.getAvatar() || profile} className="memberProfileImg" alt="i" />
        </Box>
        <CloseOutlined className="removeMemberSelect" onClick={() => this.handleCheckedItemClick(uid)} />
      </Box>
      <Typography className="memberSelected__name" component="p">
        {contact?.getName()}
      </Typography>
    </Box>
  }

  renderCreateGroup = () => {
    const { classes, onClose, checked } = this.props
    const listSelectedMember = Object.entries(this.state.checkedContacts)
      .filter(([, checked]) => checked);
    return <Fragment>
      <Box className={classes.header}>
        <Typography className={classes.headerButton} onClick={onClose}>
          Cancel
        </Typography>
        <Typography className={`${classes.headerTitle} ${clsx(checked && classes.headerTitleLight)}`}>
          New Group
        </Typography>
        <Typography className={classes.headerButton} data-test-id="nextStep1" onClick={this.nextCreateGroup}>
          Done
        </Typography>
      </Box>

      <Box className={classes.searchContainer}>
        <img
          data-test-id="selectIconGroup"
          src={this.state.createGroupIcon || cameraIcon}
          width={48}
          height={48}
          onClick={this.handleImageClick}
          style={{ cursor: 'pointer' }} />
        <input
          type="file"
          data-test-id="selectedImageIcon"
          ref={this.fileInputUploadImg}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          accept="image/*"
        />
        <Box className={classes.searchWrapper} style={{ flex: 1 }}>
          <Input
            id="input-groupName"
            className={classes.searchField}
            placeholder="Group Name"
            onChange={this.handleChangeGroupName}
            value={this.state.createGroupName}
            endAdornment={this.renderEmotionPicker()}
          />
        </Box>
      </Box>

      <Box className={classes.settingContainer}>
        <Box className={classes.settingWrapper}
          data-test-id="btnDisappearingMessage"
          onClick={() => this.handleToggleModalDisappearing(this.state.isOpenModalDisappearing)}
        >
          <Box className={classes.settingItem}>
            <Typography className={`${classes.settingTitle} ${clsx(checked && classes.settingTitleLight)}`}>Disappearing messages</Typography>
            <Typography className={classes.settingDesc}>{DISAPPEARING_MESSAGES_SETTINGS.find(setting => setting.value === this.state.groupsPermissionSetting.disapearingMessages)?.label}</Typography>
          </Box>
          <img src={checked ? rightBlackIcon : rightIcon} />
        </Box>
        <Box className={classes.settingWrapper} data-test-id="groupPermissionBtn" onClick={() => this.handleChangeGroupScreen("permission")}>
          <Typography className={`${classes.settingTitle} ${clsx(checked && classes.settingTitleLight)}`}>Group permission</Typography>
          <img src={checked ? rightBlackIcon : rightIcon} />
        </Box>
        <Box className={classes.settingWrapper}>
          <Typography className={`${classes.settingTitle} ${clsx(checked && classes.settingTitleLight)}`}>Create Group Plan</Typography>
          <img src={checked ? rightBlackIcon : rightIcon} />
        </Box>
      </Box>

      {
        !!listSelectedMember.length && <Box className="membersSelectedContainer">
          <Typography className={`memberCount ${clsx(checked && classes.lightThemeText)}`} component="p">
            Members: {listSelectedMember.length}
          </Typography>

          <Box className="listMemberGroup">
            {
              listSelectedMember.map(([uid]) => (this.renderListMemberSelected(uid)))
            }
          </Box>
        </Box>
      }

      <Box className={classes.searchWrapper}>
        <Input
          id="input-with-icon-adornment"
          className={classes.searchField}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <img src={searchWhite} />
            </InputAdornment>
          }
        />
      </Box>

      {this.renderContactList({
        title: "Frequently Contacted",
        contacts: this.state.listFrequentlyContact,
        checkedContacts: this.state.checkedContacts,
        onCheckboxChange: this.handleCheckboxChange,
      })}

      {this.renderContactList({
        title: "Contacts on Arrow",
        contacts: this.state.listContact,
        checkedContacts: this.state.checkedContacts,
        onCheckboxChange: this.handleCheckboxChange,
      })}

      {this.renderDisapearingMessages()}
    </Fragment>
  }

  renderGroupsPermission = () => {
    return <GroupsPermissionContainerStyled>
      <Box className={`themeMode ${clsx(this.props.checked && "lightTheme")}`}>
        <Box className="groupPermissionContainer__header">
          <IconButton className="backArrowButton" onClick={() => this.handleChangeGroupScreen("create")}>
            <img width={24} height={24} alt="i" src={this.props.checked ? backArrowDark : backArrowWhite} />
          </IconButton>
          <Box>
            <Typography className="labelContainer__header">
              Group permission
            </Typography>
          </Box>
        </Box>
        <GroupsPermission
          checked={this.props.checked}
          defaultSetting={this.state.groupsPermissionSetting}
          onChangeSetting={this.handleChangeGroupsPermissionSetting}
        />
      </Box>
    </GroupsPermissionContainerStyled>
  }

  renderCurrentGroupScreen = () => {
    const { currentGroupScreen } = this.state
    switch (currentGroupScreen) {
      case "create":
        return this.renderCreateGroup();
      case "permission":
        return this.renderGroupsPermission();
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Box className={classes.container}>
        {this.renderCurrentGroupScreen()}
      </Box>
    );
  }
}

const GroupsPermissionContainerStyled = styled(Box)({
  "& .themeMode": {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& .groupPermissionContainer__header": {
      display: "flex",
      alignItems: "center",
      marginTop: "15px",
      "& .backArrowButton": {
        padding: "2px 4px",
        width: "48px",
        height: "48px",
        marginLeft: "-20px"
      },
      "& .labelContainer__header": {
        fontFamily: "Manrope",
        fontWeight: 800,
        fontSize: "24px",
        color: "#FFFFFF"
      }
    }
  },
  "& .themeMode.lightTheme": {
    "& .groupPermissionContainer__header": {
      "& .labelContainer__header": {
        color: "#222222"
      }
    }
  }
})

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: "30px",
    "& > *:not(:nth-child(3))": {
      margin: "0 20px"
    },
    "& .membersSelectedContainer": {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      "& .memberCount": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        color: "#FFFFFF"
      },
      "& .listMemberGroup": {
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        "& .memberContainer": {
          maxWidth: "48px",
          "& .memberSelected": {
            width: "48px",
            height: "48px",
            position: "relative",
            "& .memberSelected__profile": {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "32px",
              background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
              padding: "2px",
              boxSizing: "border-box",
              "& .memberProfileImg": {
                display: "block",
                width: "100%",
                height: "100%",
                borderRadius: "30px",
                objectFit: "cover"
              },
            },
            "& .removeMemberSelect": {
              cursor: "pointer",
              width: 12,
              height: 12,
              color: "white",
              padding: "1px",
              fontSize: "10px",
              position: "absolute",
              right: 0,
              bottom: 0,
              background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
              borderRadius: "16px"
            }
          },
          "& .memberSelected__name": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Manrope",
            fontWeight: 400,
            fontSize: "8px",
            color: "#81999E",
            textAlign: "center"
          }
        }
      }
    },
  },
  header: {
    paddingTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerButton: {
    color: '#8833FF',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Manrope',
    cursor: 'pointer',
  },
  headerTitle: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 800,
    fontFamily: 'Manrope',
  },

  headerTitleLight: {
    color: '#222222',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  searchWrapper: {
    height: "48px",
    padding: '0px 15px',
    border: '1px solid #6B6B6B',
    borderRadius: '10px',
    alignContent: 'center',
  },
  searchField: {
    '&::after': {
      display: "none",
    },
    '&::before': {
      display: "none",
    },
    width: "100%"
  },
  settingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '10px 0',
    borderBottom: '2px solid #222222',
    "& > *": {
      margin: "0 20px"
    },
  },
  settingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 0',
    cursor: 'pointer',
  },
  settingItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  settingTitle: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Manrope',
  },
  settingTitleLight: {
    color: '#222222',
  },
  settingDesc: {
    fontSize: '12px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    color: '#6B6B6B',
  },
  suggestPeopleContainer: {
    "& .listContactUser": {
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
      gap: 16,
      "& .contactUser": {
        display: "flex",
        justifyContent: "space-between",
        "& .contactUserInformation": {
          display: "flex",
          alignItems: "center",
          gap: 10,

        }
      }
    }
  },
  suggestPeopleTitle: {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 600,
    color: '#6B6B6B',
  },
  lightThemeText: {
    color: "#222222!important"
  }
})

export default withStyles(styles)(Groups);


// Customizable Area End
