export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const lightBackground=require("../assets/lightBackground.png");
export const darkBackground=require("../assets/darkBackground.png");
export const darkBg=require("../assets/darkBg.png");
export const lightBg = require("../assets/lightBg.png");
export const RightIcon = require("../assets/lefticon.svg");

export const profileWhite=require("../assets/Profile-white.png");
export const profileDark=require("../assets/Profile-dark.png");

export const securityWhite=require("../assets/Lock-white.png");
export const securityDark=require("../assets/Lock-dark.png");

export const privacyWhite=require("../assets/Shield-white.png");
export const privacyDark=require("../assets/Shield-dark.png");

export const notificationWhite=require("../assets/notification-white.png");
export const notificationDark=require("../assets/notification-dark.png");

export const accDisplayWhite=require("../assets/accessibility-white.png");
export const accDisplayDark=require("../assets/accessibility-dark.png");

export const resourcesWhite=require("../assets/Info-white.png");
export const resourcesDark=require("../assets/Info-dark.png");

export const modeWhite=require("../assets/mode-white.png");
export const modeDark=require("../assets/mode-dark.png");

export const backArrowWhite=require("../assets/backArrow-white.png");
export const backArrowDark=require("../assets/backArrow-dark.png");

export const searchWhite=require("../assets/search-white.png");
export const searchDark=require("../assets/search-dark.png");

export const arrowWhite=require("../assets/arrow-right-white.png");
export const arrowDark=require("../assets/arrow-right-dark.png");

export const arrowRight24White=require("../assets/rightArrow-24-white.png");

export const downloadWhite=require("../assets/download-white.png");
export const downloadDark=require("../assets/download-dark.png");

export const userRemoveWhite=require("../assets/user-remove-white.png");
export const userRemoveDark=require("../assets/user-remove-dark.png");

export const phoneNumerWhite=require("../assets/Call-white.png");
export const phoneNumerDark=require("../assets/Call-dark.png");

export const emailWhite=require("../assets/email-white.png");
export const emailDark=require("../assets/email-dark.png");

export const calenderWhite=require("../assets/Calendar-white.png");
export const calenderDark=require("../assets/Calendar-dark.png");

export const calender20White=require("../assets/Calendar20White.png");
export const calender20Dark=require("../assets/Calendar20Dark.png");

export const logoutIcon=require("../assets/Logout.png");
export const sucIcon=require("../assets/suc-icon.png");
export const arrowRightRed=require("../assets/arrowRight-red.png");

export const radioCheckedDark=require("../assets/radioButtonChecked.png");
export const radioCheckedLight=require("../assets/radioButtonCheckedLight.png");
export const radioUnChecked=require("../assets/radioButtonUnchecked.png");
export const crossWhiteIcon=require("../assets/crossButtonWhite.png");
export const crossBlackIcon=require("../assets/crossButtonWhite.png");

export const notification=require("../assets/notificationOff.png")
export const vibratePhone=require("../assets/vibrateMode.png")

export const notificationLight=require("../assets/notifyLight.png")
export const vibratePhoneLight=require("../assets/vibrateLight.png")


export const unCheck=require("../assets/buttonCheck.png")
export const check=require("../assets/buttonUncheck.png")

export const profile=require('../assets/profile.png')
export const verify=require('../assets/verify.png')

export const mainCategoryList = [
    {
        whiteIcon: profileWhite,
        darkIcon: profileDark,
        name: "Your Account",  
        path: "SettingsProfile",             
    },
    {
        whiteIcon: securityWhite,
        darkIcon: securityDark,
        name: "Security & account access",    
        path: "SettingsSecurity"     
    },
    {
        whiteIcon: privacyWhite,
        darkIcon: privacyDark,
        name: "Privacy & Safety",   
        path: "PrivacySafety"      
    },
    {
        whiteIcon: notificationWhite,
        darkIcon: notificationDark,
        name: "Notification",    
        path: "SettingsNotification"     
    },
    {
        whiteIcon: accDisplayWhite,
        darkIcon: accDisplayDark,
        name: "Accessibility & display", 
        path: "SettingsAccessibility"        
    },
    {
        whiteIcon: resourcesWhite,
        darkIcon: resourcesDark,
        name: "Additional resources", 
        path: "SettingsResources"        
    }     
]

export const accountSettingsList = [
    {
        whiteIcon: profileWhite,
        darkIcon: profileDark,
        name: "Account Information",  
        description: "See your account information like your phone number and email address.",
        path: "accountInformation"       
    },
    {
        whiteIcon: securityWhite,
        darkIcon: securityDark,
        name: "Change Your Password",   
        description: "Change your password at any time.", 
        path: "changePassword"     
    },
    {
        whiteIcon: downloadWhite,
        darkIcon: downloadDark,
        name: "Download an archive of your data", 
        description:"Get insights into the type of information stored for your account.",  
        path: "default"      
    },
    {
        whiteIcon: userRemoveWhite,
        darkIcon: userRemoveDark,
        name: "Deactivate your account", 
        description:"Find out how you can deactivate your account.",  
        path: "deactivateAccount"        
    }   
]

export const accountInformationList = [
    {
        whiteIcon: profileWhite,
        darkIcon: profileDark,
        label: "User name",
        keyPair: "name"       
    },
    {
        whiteIcon: phoneNumerWhite,
        darkIcon: phoneNumerDark,
        label: "Phone Number",
        keyPair: "phoneNumber"      
    },
    {
        whiteIcon: emailWhite,
        darkIcon: emailDark,
        label: "Email",
        keyPair: "email",
        fieldName: "user_name",
        fieldLabel: "UserName",
    }
]
export const back_btn = require("../assets/back_btn.svg");
export const audience_and_tagging = require("../assets/audience_and_tagging.svg");
export const content_you_see = require("../assets/content_you_see.svg");
export const direct_msg = require("../assets/direct_msg.svg");
export const discoverability = require("../assets/discoverability.svg");
export const mute_and_block = require("../assets/mute_and_block.svg");
export const spaces = require("../assets/spaces.svg");
export const your_post = require("../assets/your_post.svg");
export const right_arrow = require("../assets/right_arrow.svg");
export const checkbox_tick = require("../assets/checkbox_tick.svg");
export const back_btn_black = require("../assets/back_btn_black.svg");
export const right_arrow_black = require("../assets/right_arrow_black.svg");
export const audience_black = require("../assets/audience_black.svg");
export const your_posts_black = require("../assets/your_posts_black.svg");
export const content_black = require("../assets/content_black.svg");
export const mute_black = require("../assets/mute_black.svg");
export const dm_black = require("../assets/dm_black.svg");
export const spaces_black = require("../assets/spaces_black.svg");
export const discoverability_black = require("../assets/discoverability_black.svg");
export const cross = require("../assets/cross.svg");
export const cross_black = require("../assets/cross_black.svg");

export const googleLogo = require('../assets/googleLogo.svg');
export const lockIconDark = require('../assets/lockIconDark.svg');
export const appsAndSessionDark = require('../assets/AppsAndSessionIconDark.svg');
export const connectedAccountDark = require('../assets/connectedAccountDark.svg');
export const forwardArrowDark = require("../assets/forwardArrowDark.svg");
export const appleIconDark = require('../assets/appleIconDark.svg');

export const backArrowLight = require('../assets/backArrowLight.svg');
export const lockIconLight = require('../assets/lockIconLight.svg');
export const appsAndSessionLight = require('../assets/appsAndSessionLight.svg');
export const connectedAccountLight = require('../assets/connectedAccountLight.svg');
export const forwardArrowLight = require('../assets/forwardArrowLight.svg');
export const appleIconLight = require('../assets/appleIconLight.svg');
export const bufferLogo = require('../assets/bufferLogo.svg');
export const zohoLogo = require('../assets/zohoLogo.svg');
export const phoneIconDark = require('../assets/phoneIconDark.svg');
export const phoneIconLight = require('../assets/phoneIconLight.svg');
export const webIconDark = require('../assets/webIconDark.svg');
export const webIconLight = require('../assets/webIconLight.svg');
export const crossIconLight = require('../assets/crossIconLight.svg');
export const crossIconDark = require('../assets/crossIconDark.svg');
export const downArrowIconDark = require('../assets/downArrowIconDark.svg');
export const downArrowIconLight = require('../assets/downArrowIconLight.svg');
export const upArrowIconDark = require("../assets/upArrowIconDark.svg");
export const upArrowIconLight = require("../assets/upArrowIconLight.svg");

export const searchIconDark = require('../assets/searchIconDark.svg');
export const searchIconWhite = require('../assets/searchIconWhite.svg');
export const greenRightIcon = require('../assets/greenRightIcon.svg');

export const dataUsesWhite = require('../assets/dataUsesWhite.svg');
export const dataUsesDark = require('../assets/dataUsesDark.svg');

export const checkedImage = require('../assets/checkedImage.svg');
export const checkedEmptyImage = require('../assets/checkedEmptyImage.svg');

export const rightArrowDisabled = require('../assets/rightArrowDisabled.svg');

export const logoXlIcon = require("../assets/logo110x107.png")
export const appleIcon = require("../assets/AppleIcon.png")
