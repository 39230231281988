import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Input,
  InputAdornment,
  Tabs,
  Tab,
  createStyles,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { searchDark, searchWhite, usersDark, usersLight } from "./assets";
import { ConversationContent } from "./ViewChat.web";
import MessageItem from './MessageItem.web'
import { Conversation } from "./MessageItemController.web";
import CreateGroupChat from '../../groups/src/Groups.web'

// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderCategoriesContent = (classes: any) => {
    const { isLoading, conversationList, searchKeyword, searchResults, selectedConversation, activeIndex } = this.state
    const renderedConversations = searchKeyword ? searchResults : conversationList

    if (isLoading) {
      return (
        <Box className={classes.chatLoadingWrapper}>
          <CircularProgress size={32} style={{ color: '#8a33f0' }} />
        </Box>
      )
    }

    if (!isLoading && conversationList.length === 0 && !searchKeyword && !selectedConversation && activeIndex === "All") {
      return (
        <Box className={classes.chatLoadingWrapper}>
          <Typography className={classes.startChatTitle}>Start chatting</Typography>
          <Typography className={classes.startChatDesc}>
            Message privately with your ArrowsApp People, no matter what device they use.
          </Typography>
        </Box>
      )
    }

    return (
      <>
        {!searchKeyword && this.renderTabs(classes)}
        <Box className={classes.chatDisplay}>
          {renderedConversations.map((conversation: Conversation) => {
            let isSelectConversation = false;
            if (selectedConversation) {
              const { uid, guid } = selectedConversation as unknown as { uid: string, guid: string };
              if (conversation.id === uid || conversation.id === guid) {
                conversation = this.resetUnreadMessageConversation(conversation);
                isSelectConversation = true;
              }
            }
            return (
              <MessageItem
                checked={this.props.checked}
                key={conversation.id}
                conversation={conversation}
                onClickConversation={this.handleSelectConversation}
                isSelectingConversation={isSelectConversation}
              />
            )
          })}
        </Box>
      </>
    )
  }

  renderMainCat = (classes: any) => {
    const { checked } = this.props

    return (
      <Box className={classes.mainCategory}>
        <Box className={classes.chatTopHeader}>
          <Typography className={classes.chatTitle}>Chats</Typography>
          <img src={checked ? usersDark : usersLight} alt="" onClick={this.handleShowCreateGroupChat} />
        </Box>
        <Box className={classes.searchContainer}>
          <Input
            id="input-with-icon-adornment"
            className={classes.searchField}
            placeholder="Search"
            value={this.state.searchKeyword}
            onChange={this.handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <img src={checked ? searchDark : searchWhite} />
              </InputAdornment>
            }
          />
        </Box>
        {this.renderCategoriesContent(classes)}
      </Box>
    )
  }

  renderSideCat = (classes: any) => {
    const { selectedConversation, showCreateGroupChat } = this.state

    if (showCreateGroupChat) {
      return (
        <Box className={`${classes.messageContentContainer} ${classes.createGroupContainer}`}>
          <CreateGroupChat
            checked={this.props.checked}
            onClose={this.handleCloseCreateGroupChat}
          />
        </Box>
      )
    }

    return (
      <Box className={classes.messageContentContainer}>
        {selectedConversation && this.state.loggedCometChatUser && (
          <ConversationContent
            navigation={this.props.navigation}
            checked={this.props.checked}
            id=""
            onSendMessage={this.sendMessage}
            onSendMediaMessage={this.sendMediaMessage}
            conversation={selectedConversation}
            onCloseSelectedConversation={this.handleCloseSelectedConversation}
          />
        )}
      </Box>
    )
  }

  renderTabs = (classes: any) => {
    return <Tabs
      value={this.state.activeIndex}
      onChange={this.handleTabsChange}
      classes={{ indicator: classes.indicator }}
      textColor="primary"
      variant="fullWidth"
      data-test-id="tabBtn"
    >
      <Tab label="All" value="All" className={classes.tabstyle} />
      <Tab label="Groups" value="Group" className={classes.tabstyle} />
    </Tabs>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props

    return (
      <NavigationMenu navigation={this.props.navigation} tabItem="Chats" id={""} checked={this.props?.checked}>
        <Box style={{ display: 'flex' }}>
          {this.renderMainCat(classes)}
          {this.renderSideCat(classes)}
        </Box>
      </NavigationMenu>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = createStyles({
  mainCategory: {
    height: '100vh',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "Manrope",
    padding: '0 15px',
    flex: 4,
    maxWidth: '420px',
    borderRight: '2px solid #222222',
  },
  chatTopHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    paddingTop: '15px',
  },
  chatTitle: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 800,
  },
  searchContainer: {
    border: '1px solid #6B6B6B',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
  },
  searchField: {
    '&::before': {
      display: "none",
    },
    '&::after': {
      display: "none",
    },
    width: "100%"
  },
  indicator: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  tabstyle: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "capitalize" as "capitalize",
    borderBottom: "3px solid gray",
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent' as "transparent",
    },
  },
  chatDisplay: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  chatLoadingWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  startChatTitle: {
    fontSize: "24px",
    fontFamily: "Manrope",
    fontWeight: 800,
    backgroundClip: 'text',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  startChatDesc: {
    ontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    color: '#FFFFFF',
  },
  messageContentContainer: {
    flex: 6,
    maxWidth: '680px',
    borderRight: '2px solid #222222',
  },
  createGroupContainer: {
    height: "100vh",
    overflowY: "auto"
  }
})

export const ChatScreen = withStyles(styles)(Chat)
// Customizable Area End
