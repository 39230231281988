// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

const styles = theme => ({
  '@global': {
    html: {
      fontSize: 10,
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 10
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 11.5
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16
      }
    }
  }
});

const App1 = withStyles(styles)(App);

ReactDOM.render(
  <Router>
    <div style={{ height: '100vh', width: '100vw' }}>
      <App1 />
      <ToastContainer />
    </div>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
