import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {cross_black, cross, audience_black, your_posts_black, content_black, mute_black, dm_black, spaces_black, discoverability_black, right_arrow,right_arrow_black, back_btn, back_btn_black,  audience_and_tagging, content_you_see, direct_msg, discoverability, mute_and_block, spaces, your_post } from "./assets";


// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export interface ApiData{
  method:string;
  contentType: string;
  endPoint: string; 
  body?:{}
}
// Customizable Area End

export const configJSON = require("./config");
export interface MockEventType {
  target: {
    checked: boolean;
  };
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean;
  // Customizable Area End
}

interface S {
  subType: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  categories: {
    id: number;
    whiteLogo: string;
    blackLogo: string;
    title: string;
    description: string;
  }[];
  photoTagging: string;
  isDarkMode: boolean;
  currentView: string,
  checkboxes: {
    checkbox1: boolean;
    checkbox2: boolean;
    checkbox3: boolean;
  };
  photoTag: {
    btn1: boolean,
    btn2: boolean,
    btn3: boolean,
  },
  showPhotoTagModal: boolean,
  isLoading: boolean
  pathName: string
  checked: boolean
  logoutModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  unregDeviceApiId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      subType: window.location.pathname.slice(1),
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      categories: [
        {id: 1, whiteLogo: audience_and_tagging, blackLogo: audience_black, title: "Audience and tagging", description: "Manage what information you allow other people on Arrows to see."},
        {id: 2, whiteLogo: your_post, blackLogo: your_posts_black, title: "Your posts", description: "Manage the information associated with your posts."},
        {id: 3, whiteLogo: content_you_see, blackLogo: content_black, title: "Content you see", description: "Decide what you see on Arrows based on your preferences."},
        {id: 4, whiteLogo: mute_and_block, blackLogo: mute_black, title: "Mute and block", description: "Manage the accounts, words and notifications that you've muted or blocked."},
        {id: 5, whiteLogo: direct_msg, blackLogo: dm_black, title: "Direct messages", description: "Manage who can message you directly."},
        {id: 6, whiteLogo: spaces, blackLogo: spaces_black, title: "Spaces", description: "Manage your Spaces activity"},
        {id: 7, whiteLogo: discoverability, blackLogo: discoverability_black, title: "Discoverability and contacts", description: "Control your discoverability settings and manage contacts you've imported."},
      ],
      photoTagging: "Off",
      isDarkMode: false,
      currentView: 'main',
      checkboxes: {
        checkbox1: true,
        checkbox2: false,
        checkbox3: false,
      },
      photoTag: {
        btn1: false,
        btn2: false,
        btn3: false,
      },
      showPhotoTagModal: false,
      pathName: "",
      checked: false,
      logoutModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    window.addEventListener("localStorageThemeChanged", () => {
      const currentTheme = localStorage.getItem("theme");
      this.setState({checked: currentTheme == "false"? false : true})
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.unregDeviceApiId) {
          localStorage.removeItem("v") ;
          localStorage.removeItem("authToken") ;
          localStorage.removeItem("userInfo") ;
          localStorage.removeItem("cometChatToken") ;
          this.setNavigation("Home") ;
        }
        
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // web-settings-starts
 
  async componentDidMount(): Promise<void> {
    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e =>{
    //   this.setState({ isDarkMode: !e.matches, checked: !e.matches });
    //   localStorage.setItem('theme',JSON.stringify(e.matches));

    // });

    const token =  localStorage.getItem("authToken");
    if(!token){
      this.setNavigation("Home")
    }
    const pathName = window.location.pathname;
    const currentTheme = localStorage.getItem("theme") || "false";
    this.setState({checked: currentTheme == "false"? false : true, pathName: pathName})     
  }
  setNavigation=(route:string)=>{
    // this.props.navigation.navigate(route)
    this.setState({subType:route});
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),route
    );
    this.send(message);
  }
  // web-settings-ends

  getImageUrl = (isDarkMode: boolean, imageType: 'back' | 'right') => {
    const imageMap = {
      back: {
        dark: back_btn,
        light: back_btn_black,
      },
      right: {
        dark: right_arrow,
        light: right_arrow_black,
      },
    };
    
    return isDarkMode ? imageMap[imageType].dark : imageMap[imageType].light;
  }

  handleCheckboxChange = (name: keyof S['checkboxes']) => {
    this.setState((prevState) => ({
      checkboxes: {
        checkbox1: name === 'checkbox1',
        checkbox2: name === 'checkbox2',
        checkbox3: name === 'checkbox3',
      },
    }));
  };

    handlePhotoTagging = (name: keyof S['photoTag']) => {
      this.setState((prevState) => ({
        photoTag: {
          btn1: name === 'btn1',
          btn2: name === 'btn2',
          btn3: name === 'btn3',
        },
      }));
    }
  
    getCloseButtonUrl = (isDarkMode: boolean) => {
      return isDarkMode ? cross : cross_black;
    }
  
    onDarkModeChanged = (event: MockEventType) => {
      this.setState({checked: !event.target.checked})
      localStorage.setItem('theme',JSON.stringify(!event.target.checked))
    }

    showLogoutModal = () => {
      this.setState({ logoutModal:true })
    }  

    onCloseLogout = () => {
      this.setState({ logoutModal:false })
    }
    apiCall=(data:ApiData) =>{
      const { contentType, method, endPoint,body } =data;
      const token =localStorage.getItem("authToken") || "";
      const header = { "Content-Type": contentType,token: token};
      const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
      requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
      );
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body&&requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return  requestMessage.messageId;
    };

    onLogout = () => {
      this.onCloseLogout();
      const deviceId = localStorage.getItem("deviceId");
      this.unregDeviceApiId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: "DELETE",
        endPoint: `account_block/auth_tokens/${deviceId}`,
      });
    }
  
  // Customizable Area End
}
