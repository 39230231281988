import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Grid,
    Tabs,
    Tab,
    Select,
    TextField,
    Typography,
    styled,
    Chip,
    MenuItem,
    withStyles,
    ListItemIcon,
    Button,
    Popover,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';
import * as Yup from "yup";
import { ArrowIcon, darkArrowIcon, backCover, defaultUserPic, ckeckedLogo, ImageCamera } from "./assets"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditProfileModalController, { Props, InitialValues, ICategroy, SubCategroy ,configJSON } from './EditProfileModalController.web';
import { Formik, FormikProps } from 'formik';


const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/^[a-zA-Z\s'-]+$/, 'Invalid name format').required('Name is required'),
    bio: Yup.string(),
    occuption:Yup.string(),
    website:Yup.string().matches(configJSON.websiteRegex,"invalid format")
}) 


// Customizable Area End

export default class EditProfileModal extends EditProfileModalController {

    constructor(props: Props) {
        // Customizable Area Start      
        super(props);
        // Customizable Area End  
    }
    // Customizable Area Start

    CustomMenuSelect = withStyles((theme) => ({
        root: {
            backgroundColor: this.props.checked ? "var(--Neutrals-Cool-gray-50, #E9E9E9)" : "var(--Neutrals-Cool-gray-500, #222)",
            '&:hover': {
                backgroundColor: this.props.checked ? '#d3d3d3' : "black",
            },
            '&.Mui-selected': {
                background: this.props.checked ? "var(--Neutrals-Cool-gray-50, #E9E9E9)" : "var(--Neutrals-Cool-gray-500, #222)",
                fontWeight: 600,
                fontSize: "16px"
            },
            '&.Mui-selected:hover': {
                backgroundColor: this.props.checked ? '#d3d3d3' : "black"
            },
            '&.MuiList-padding': {
                padding: "0px !important"
            },
            list: {
                padding: "0px !important"
            },
            display: "flex",
            width: "100%",
            height: "44px !important",
            alignItems: "center",
            gap: "8px",
            fontSize: "14px",
            justifyContent: 'space-between',
            fontWeight: 500,
        },
    }))(MenuItem);

    countryPopover=()=>{
        const popper = Boolean(this.state.anchorEl)
        const id = popper ? 'simple-popper' : undefined;
        return(
           <CustomPopover
            id={id} 
            open={popper} 
            data-testid="popover"
            anchorEl={this.state.anchorEl} 
            onClose={this.handleMenuClose}
            style={{background: "rgba(0, 0, 0, 0.4)bottom",zIndex:99999}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            >
            <Box>  
             {this.state.countryFiter.map((item)=>{
                return(
                    <MenuBoxContainer data-testid="menuItem" onClick={()=>this.handleCountryChange(item.country)} key={item.isoCode2}>
                       <Box>
                        <Typography className="item_list_name">{item.country}</Typography>
                      </Box>
                      <Box>
                        <img  
                            width={36}
                            height={18}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item.isoCode2}.svg`}/>
                      </Box>
                    </MenuBoxContainer>
                )
             })}
             </Box>
           </CustomPopover> 
        )
      }



    conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
        return condition ? trueStatement : falseStatement
    }


    renderBasicDetails = (
        formikProps: FormikProps<InitialValues>
    ) => {
        const { values, touched, errors, handleChange } = formikProps;
        return (
            <Box>
                <Box><Typography className='Tabtitle'>Personal Details</Typography></Box>
                <Box style={{ marginTop: 10 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "6px 12px" }}>
                            <Box className= {this.conditionRemover(values.name.length>0,"borderTextfield","")}>
                                <Typography className='lableText'>Name</Typography>
                                <CustomTextField
                                    name={"name"}
                                    data-testid="name"
                                    value={values.name}
                                    style={{borderColor:'green'}}
                                    autoComplete={"off"}
                                    onChange={handleChange}
                                    error={touched.name && !!errors.name}
                                    placeholder={"Enter name"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "4px 12px" }}>
                            <Box className= {this.conditionRemover(values.occuption.length>0,"borderTextfield","")}>
                                <Typography className='lableText'>Occupation</Typography>
                                <CustomTextField
                                    name={"occuption"}
                                    value={values.occuption}
                                    data-testid="occuption"
                                    onChange={handleChange}
                                    autoComplete={"off"}
                                    className="TextField"
                                    error={touched.occuption && !!errors.occuption}
                                    placeholder={"Enter Occupation"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Box style={{ marginTop: 10 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "4px 12px" }}>
                            <Box className= {this.conditionRemover(this.state.country.length>0,"borderTextfield","")}>
                                <Typography className='lableText'>Country</Typography>
                                <CustomTextField
                                    name={"country"}
                                    value={this.state.country}
                                    autoComplete={"off"}
                                    data-testid="country"
                                    placeholder={"Enter Country"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    InputProps={{endAdornment:<Box onClick={this.openCountryPopover}>
                                        <KeyboardArrowDownIcon style={{ color: this.conditionRemover(this.props.checked, "balck", "white"),height:24,width:24,cursor:'pointer' }}/>
                                        </Box>}}
                                />
                                
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "4px 12px" }}>
                            <Box className={this.conditionRemover(values.website.length>0,"borderTextfield","")}>
                                <Typography className='lableText'>Website</Typography>
                                <CustomTextField
                                    name={"website"}
                                    data-testid="website"
                                    onChange={handleChange}
                                    value={values.website}
                                    className="TextField"
                                    autoComplete={"off"}
                                    error={touched.website && !!errors.website}
                                    placeholder={"Enter website"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{ marginTop: 10, paddingBottom: 36 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} style={{ padding: "4px 12px" }}>
                            <Box className={this.conditionRemover(values.bio.length>0,"borderTextfield","")}>
                                <Typography className='lableText'>Bio</Typography>
                                <CustomTextField
                                    name={"bio"}
                                    value={values.bio}
                                    onChange={handleChange}
                                    className="TextField"
                                    autoComplete={"off"}
                                    error={touched.bio && !!errors.bio}
                                    placeholder={"Enter bio"}
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    id="bio"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }


    renderCategoriesDetails = () => {
        const { categories, selectedCategories, selectedSubCategories } = this.state
        return (
            <Box>
                <Box><Typography className='Tabtitle'>Categories</Typography></Box>
                <Box style={{ marginTop: 10, paddingBottom: 36 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "6px 12px" }}>
                            <Box>
                                <Typography className='lableText'>Category</Typography>
                                <Box>
                                    <CustomSelect
                                        IconComponent={(iconProps) => (
                                            <KeyboardArrowDownIcon  {...iconProps} style={{ color: this.conditionRemover(this.props.checked, "balck", "white") }} />
                                        )}
                                        name="category"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedCategories.map((category: ICategroy) => category.attributes.id)}
                                        onChange={(event)=>this.handleChangeCategories(event)}
                                        MenuProps={{
                                            style:{height:300,padding:0},
                                            classes: { paper: "menuPaper", list: "menuList" },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        renderValue={() => "Add Category"}
                                        multiple={true}
                                        displayEmpty
                                        id="category"
                                        data-testid="category"
                                        style={{
                                            borderColor: this.conditionRemover(selectedCategories.length > 0, "#8833FF", "")
                                        }}
                                    >
                                        {categories.map(((item) => <this.CustomMenuSelect key={item.id} value={item.attributes.id}>
                                            {item.attributes.name}
                                            <ListItemIcon style={{ display: "flex", justifyContent: "end" }}>
                                                {item.attributes.is_selected ?
                                                    <img src={ckeckedLogo} alt="" /> :
                                                    <span
                                                        style={{ width: "20px", height: "20px", border: "1px solid #6B6B6B", borderRadius: "50%" }}>
                                                    </span>}
                                            </ListItemIcon>
                                        </this.CustomMenuSelect>))}

                                    </CustomSelect>
                                    <Box className={"chipStyle"}>
                                        {selectedCategories.map((item:ICategroy) => {
                                            return <Chip label={item.attributes.name}
                                                className={"chipStyleInner"}
                                                onDelete={() => this.handleDeleteCategories(item.id)}
                                                data-test-id="chipId"
                                                deleteIcon={<ClearIcon style={{ color: "white", width: "12px" }}
                                                />} />
                                          }
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "4px 12px" }}>
                            <Box>
                                <Typography className='lableText'>Sub-category</Typography>
                                <Box>
                                    <CustomSelect
                                        IconComponent={(iconProps) => (
                                            <KeyboardArrowDownIcon  {...iconProps} style={{ color: this.conditionRemover(this.props.checked, "balck", "white") }} />
                                        )}
                                        MenuProps={{
                                            style:{height:300,padding:0},
                                            classes: { paper: "menuPaper", list: "menuList" },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        name="subCategory"
                                        fullWidth
                                        variant="outlined"
                                        id="subCategory"
                                        multiple={true}
                                        data-testid="subCategorey"
                                        renderValue={() => "Add Sub-Category"}
                                        displayEmpty
                                        style={{
                                            borderColor: this.conditionRemover(selectedSubCategories.length > 0, "#8833FF", "")
                                        }}
                                        value={selectedSubCategories.map((category: SubCategroy) => category.id)}
                                    >

                                        {categories.filter((category: ICategroy) => category.attributes.is_selected).map((category) => (
                                            <React.Fragment key={category.id}>
                                                <this.CustomMenuSelect key={category.id} >
                                                    <Typography variant="subtitle1" className={"colorFullText"}>{category.attributes.name}</Typography>
                                                </this.CustomMenuSelect>
                                                {category.attributes.sub_categories.map((subCategory: SubCategroy) => (
                                                    <this.CustomMenuSelect style={{ fontSize: this.conditionRemover(subCategory.is_selected, "16px", "14px"), fontWeight: this.conditionRemover(subCategory.is_selected, "600", "500") }} key={subCategory.id} data-testid="selectSubCategory" onClick={() => this.handleChangeSubCategories(subCategory.id)} value={subCategory.id}>
                                                        {subCategory.name}
                                                        <ListItemIcon style={{ display: "flex", justifyContent: "end" }}>
                                                            {subCategory.is_selected ? <img src={ckeckedLogo} alt="" /> : <span style={{ width: "20px", height: "20px", border: "1px solid #6B6B6B", borderRadius: "50%" }}></span>}
                                                        </ListItemIcon>
                                                    </this.CustomMenuSelect>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </CustomSelect>
                                    <Box className={"chipStyle"}>
                                        {selectedSubCategories.map((subCategory) => (
                                            <Chip
                                                key={subCategory.id}
                                                label={subCategory.name}
                                                data-test-id="chipId2"
                                                className={"chipStyleInner"}
                                                onDelete={() => this.handleDeleteSubCategories(subCategory.id)}
                                                deleteIcon={<ClearIcon style={{ color: "white", width: "12px" }} />}
                                            />
                                        ))}

                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>

            </Box>
        )
    }


    renderSocialMediaDetails = (
        formikProps: FormikProps<InitialValues>
    ) => {
        const { values, touched, errors, handleChange } = formikProps;
        return (
            <Box>
                <Box><Typography className='Tabtitle'>Social media handels</Typography></Box>
                <Box style={{ marginTop: 10 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "6px 12px" }}>
                            <Box>
                                <Typography className='lableText'>Twitter(X)</Typography>
                                <CustomTextField
                                    name={"twitter"}
                                    value={values.twitter}
                                    className="TextField"
                                    data-testid="twitter"
                                    autoComplete={"off"}
                                    onChange={handleChange}
                                    error={touched.twitter && !!errors.twitter}
                                    placeholder={"Enter Twitter"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "4px 12px" }}>
                            <Box>
                                <Typography className='lableText'>Instagram</Typography>
                                <CustomTextField
                                    name={"instagram"}
                                    value={values.instagram}
                                    className="TextField"
                                    autoComplete={"off"}
                                    data-testid="instagram"
                                    onChange={handleChange}
                                    error={touched.instagram && !!errors.instagram}
                                    placeholder={"Enter Instagram"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
                <Box style={{ marginTop: 10, paddingBottom: 36 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ padding: "4px 12px" }}>
                            <Box>
                                <Typography className='lableText'>Facebook</Typography>
                                <CustomTextField
                                    name={"facebook"}
                                    value={values.facebook}
                                    data-testid="facebook"
                                    className="TextField"
                                    onChange={handleChange}
                                    autoComplete={"off"}
                                    error={touched.facebook && !!errors.facebook}
                                    placeholder={"Enter Facebook"}
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }


    renderTablist = (formikProps:FormikProps<InitialValues>) => {
        const { activeTab } = this.state
        return (
            <Box className={'tabsContainer'}>
                <Tabs
                    classes={{ indicator: "indicator" }}
                    textColor="primary"
                    className='tabs_style'
                    value={activeTab}
                    onChange={(event, newValue)=>this.handleTabChange(event,newValue,formikProps)}
                    variant="scrollable"
                    data-test-id="tabBtn"
                >
                    <Tab label="Basic" value="Basic" data-test-id="Tab" className={'tabstyle'} />
                    <Tab label="Social media" data-test-id="Tab" value="Social media" className={'tabstyle'} />
                    <Tab label="Categories" data-test-id="Tab" value="Categories" className={'tabstyle'} />
                </Tabs>
            </Box>
        )
    }
    // Customizable Area End
    render() {
        const { checked } = this.props
        // Customizable Area Start
        return (
            <DialogContainer
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <BoxContainer style={{background:(this.conditionRemover(this.props.checked,"unset","#0B0B0B"))}}>
                    <Box className={this.conditionRemover(this.props.checked,'lightContainer','container')}>
                        <Formik
                            data-testid="FormikBlock"
                            validationSchema={validationSchema}
                            initialValues={{
                                ...this.state.formValue

                            }}
                            onSubmit={this.handleFormSubmit}
                        >
                            {(formikProps: FormikProps<InitialValues>) => {
                                const { handleSubmit } = formikProps
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Box className='editContainer'>
                                            <Box className='headContainer'>
                                                <Box onClick={this.props.onClose}><img className='topIcon' src={this.conditionRemover(checked, darkArrowIcon, ArrowIcon)} /></Box>
                                                <Box><Typography className='HeadingStyle'>Edit Profile</Typography></Box>
                                                <Box><Button type="submit" className={"followBtn"}>Save</Button></Box>
                                            </Box>

                                            <Box className='profileImageContainer'>
                                                <Box className='backCover'>
                                                    <img src={this.state.coverImage ?? backCover} className='backCoverImage' data-testid="coverImage" />
                                                    <Box component="label" className='Edit_text'>
                                                      <input type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangeCoverPicture} data-test-id='uploadCoverImage' />
                                                      <Typography className='Edit_type'>
                                                        <img src={ImageCamera}  alt="camera" />
                                                        <span>{configJSON.editCoverPicture}</span>
                                                      </Typography> 
                                                    </Box>
                                                </Box>

                                                <Box className='profileImage'>
                                                    <img data-testid="avatarImage" src={this.state.profileImage ?? defaultUserPic} className='AvatarStyle' />
                                                    <Box component="label" className='Edit_text_image'>
                                                    <input type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangePicture} data-test-id='uploadImage' />
                                                      <Typography component={"span"} className='Edit_type'>
                                                        <img src={ImageCamera} alt="camera"/>
                                                        <span>{configJSON.editPicture}</span>
                                                      </Typography> 
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                {this.renderTablist(formikProps)}
                                                <Box style={{ marginTop: 24 }}>
                                                    {this.state.activeTab === "Basic" && this.renderBasicDetails(formikProps)}
                                                    {this.state.activeTab === "Social media" && this.renderSocialMediaDetails(formikProps)}
                                                    {this.state.activeTab === "Categories" && this.renderCategoriesDetails()}
                                                </Box>
                                            </Box>
                                            {this.countryPopover()}
                                        </Box>
                                    </form>
                                )
                            }}
                        </Formik>
                    </Box>
                </BoxContainer>
            </DialogContainer>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start

const DialogContainer = styled(Dialog)({

    '& .MuiDialog-paperWidthSm': {
        maxWidth: "790px",
        maxHeight: '840px',
        width: '90%',
        border: '1px solid #35383F',
        borderRadius: '10px',
    }
})


const CustomTextField = styled(TextField)({
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Manrope',
    margin:'4px',
    "& .MuiOutlinedInput-root": {
        borderRadius: '10px !important',
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF !important'
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    },
    "& .MuiInputBase-input": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    }

})

const CustomSelect = styled(Select)({
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Manrope',
    borderRadius: '10px !important',
    "&.MuiOutlinedInput-root": {
        borderRadius: '10px !important',
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF !important'
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF !important'
    },

    "& .MuiSvgIcon-root":{
      height:24,
      width:24
    },

    "& .menulist": {
        paddingTop: '0px',
        paddingBottom: '0px',
        background:'#222 !important',
        maxHeight: "364px"
    },
    "& .menuPaper": {
        marginTop: '8px',
        borderRadius: '8px !important',
        background: "none !important",
    },
    "& .MuiInputBase-input": {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Manrope',
    }
})


const BoxContainer = styled(Box)({
    maxHeight: 840,
    height: '90vh',
    display: 'flex',
    width: '100%',
    overflowX: 'unset',
    overflowY: 'auto',
    justifyContent: 'center',
    background: '#0B0B0B',
    alignItems: 'center',

    '& .container': {
        width: '100%',
        height: '100%',
        background: '#0B0B0B'
    },

    "& .lightContainer":{
        width: '100%',
        height: '100%',
    },


    "& .editContainer": {
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
        padding: '10px 20px',
    },

    "& .headContainer": {
        display: 'flex',
        width: '100%',
        height: 48,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .followBtn": {
        width: "65px",
        height: "25px",
        borderRadius: "10px",
        padding: "4px 8px 4px 8px",
        gap: "7px",
        fontSize: "12px",
        fontFamily: "Manrope",
        color: "white",
        fontWeight: 500,
        textTransform: "capitalize" as "capitalize",
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
    },

    "& .HeadingStyle": {
        fontSize: 18,
        fontFamily: 'Manrope',
        fontWeight: 800
    },

    '& .Edit_type':{
     fontFamily:'Manrope',
     fontSize:12,
     fontWeight:700,   
     height:'100%',
     display:'flex',
     flexDirection:'column',
     justifyContent:'center',
     alignItems:'center',
     color:'#fff',
    },

    "& .Edit_text":{
      position:'absolute',
      width:'100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      top:'0%',
      left:'0%',
      height:'100%'
    },

    "& .Edit_text_image":{
        position:'absolute',
        width:'120px',
        left:'0%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top:'0%',
        height:'120px',
        borderRadius:'50%'
      },
  
    "& .backCover": {
        position:'relative',
        height: 245,
        width: '100%'
    },

    "& .backCoverImage": {
        objectFit: 'cover',
        width: "100%",
        borderRadius: 10,
        height: '100%'
    },

    "& .profileImageContainer": {
      position:'relative'
    },

    "& .AvatarStyle": {
        height: 120,
        width: 120,
        borderRadius: '50%',
        objectFit: 'cover'
    },

    "& .profileImage": {
        marginTop: -70,
        marginLeft: 20,
        position:'relative'
    },
    "& .indicator": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        height: '2.9px',
    },

    "& .tabsContainer": {
        height: "35px",
        width: '100%',
    },

    "& .tabstyle": {
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: "16px",
        minWidth: 'unset !important',
        width: '33.3%',
        textTransform: "capitalize" as "capitalize",
        borderBottom: "3px solid #35383F",
        '&.Mui-selected': {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent' as "transparent",
        },
    },

    "& .Tabtitle": {
        fontSize: 18,
        fontWeight: 800,
        fontFamily: 'Manrope'
    },

    "& .lableText": {
        margin: '4px',
        fontSize: 14,
        fontFamily: 'Manrope',
        fontWeight: 500,
    },

    "& .TextField": {
        borderRadius: 10,
        borderColor: '#8833FF'
    },

    "& .chipStyle": {
        display: "block",
        alignItems: "center" as "center",
        borderRadius: "16px",
        width: "100%",
        marginTop: "12px",
        gap: "4px"
    },
    "& .chipStyleInner": {
        fontWeight: 500,
        padding: "4px 6px 4px 8px",
        fontSize: "10px",
        color: "white",
        margin: "0px 4px 4px 4px",
        fontFamily: "Manrope",
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
    },

    "& .borderTextfield > .MuiTextField-root > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8833FF',
    },

    "& .menulist": {
        paddingTop: '0px',
        paddingBottom: '0px',
        background:'#222 !important',
        maxHeight: "364px"
    },
    "& .menuPaper": {
        marginTop: '8px',
        borderRadius: '8px !important',
        background: "none !important",
    },
})

const CustomPopover=styled(Popover)({
    "& .MuiPaper-elevation8-53":{
       boxShadow:'none'
    },
    "& .MuiPopover-paper":{
     maxHeight:240
    }
  })

  const MenuBoxContainer=styled(Box)({
    width: "256px",
    height:"40px",
    display:'flex',
    margin:"8px",
    padding:'0px 8px',
    alignItems:'center',
    justifyContent:'space-between',
    cursor:'pointer',
     "& .item_list_name":{
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 500,
     }
  })
// Customizable Area End