import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    Grid,
    Avatar,
    Button,
    Card,
    CardContent,
    IconButton,
    styled,
    TextField
} from "@material-ui/core";
import { radioButtonChecked, radioButtonUncheck, darkView, view, RightIcon, paymentLogo } from "./assets";

const StyledIcon = styled(IconButton)({
    padding: 0,
    marginLeft: -4,
    "&.MuiIconButton-root:hover": {
        background: 'unset'
    }
})
// Customizable Area End

// Customizable Area Start

import {
  configJSON,
} from "./MySubscriptionsController.web";

// Customizable Area End

interface Props {
    classes:any;
    subscriptionDetails:any[]
    checked: boolean;
    activeId: number;
    handleSelectSubscription:any;
    backToSubscribePage:() =>void
    onPromoCodeChange:(value: string) => void
    promoCode:string
    applyPromoCode:()=>void;
    promoCodeDetail:{
        isValid: boolean,
        msg: string
    };
    subscribeUserAPI:()=> void;
}
export const PaymentWeb = (props: Props) =>{
    const {classes } = props
    const classStyles = classes || {}
  return (
        // Customizable Area Start
        <Grid container className={classStyles.gridContainer}>
        <Grid item className={classStyles.imageGridItem} md={5} sm={6} lg={6}>
        </Grid>
        <Grid item xs={10} sm={5} md={5} lg={5} className={classStyles.gridItem}>
            <Box className={classStyles.formBox}>
                <Avatar className={props.checked ? classStyles.avaclassStylestarLightTheme : classStyles.avatar} data-test-id='backbuttonOtp'>
                    <IconButton style={{ borderRadius: 'unset', height: '100%', width: "100%" }} disableRipple disableFocusRipple disableTouchRipple onClick={props.backToSubscribePage}>
                        <img src={props.checked ? darkView : view} />
                    </IconButton>
                </Avatar>
                <Typography data-test-id='heading' className={classStyles.subscriptionHeading}>Add your payment method</Typography>
                <Typography className={classStyles.subscriptionDescription}>
                    {configJSON.subscriptionDescription}
                </Typography>
                <Box className={`${classStyles.cardsContainer} ${classStyles.addPaymentContainer}`}>
                    <Typography className={classStyles.paymentName}>Payment method</Typography>
                    <Button className={`${classStyles.addNewBtn} ${classStyles.feature}`}>Add new</Button>
                </Box>
                <Box className={classStyles.cardsContainer}>
                    {props.subscriptionDetails?.slice(0,2).map((subscription) =>
                        <Card variant="outlined" key={subscription.id} className={subscription.attributes.subscribed ? classStyles.activeCard : classStyles.card}>
                            <CardContent>
                                <img data-test-id={`radioButton${subscription.id}`} className={classStyles.radioImage} onClick={() => props.handleSelectSubscription(subscription.id)} src={subscription.attributes.subscribed ? radioButtonChecked : radioButtonUncheck} />
                                <Box className={classStyles.paymentCardContainer}>
                                    <img data-test-id={`paymentLogo${subscription.id}`} className={classStyles.paymentLogo} height={14} width={42} src={paymentLogo} />
                                    <Box>
                                        <Typography className={classStyles.subscriptionName}>***1232</Typography>
                                        <Typography className={`${classStyles.subscriptionPrice} ${classStyles.paymentMethodName}`}>Visa | Edit</Typography>

                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    )}
                    <Box className={classStyles.cardsContainer}>
                    <Typography>Payments</Typography>
                        <Card variant="outlined" className={classStyles.card}>
                            <CardContent style={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
                                <Box className={classStyles.paymentContainer}>
                                    <Typography className={classStyles.paymentText}>Plan renew date</Typography>
                                    <Typography className={classStyles.paymentText}>Plan renew date</Typography>
                                </Box>
                                <Box className={classStyles.paymentContainer}>
                                    <Typography className={classStyles.paymentText}>Type of plan</Typography>
                                    <Typography className={classStyles.paymentText}>Plan renew date</Typography>
                                </Box>
                                <Box className={classStyles.paymentContainer}>
                                    <Typography className={classStyles.paymentText}>Amount</Typography>
                                    <Typography className={classStyles.paymentText}>Plan renew date</Typography>
                                </Box>

                            </CardContent>
                        </Card>
                    </Box>
                    <Card variant="outlined" className={classStyles.activeCard}>
                        <CardContent>
                            <Box className={classStyles.promoCodeContainer}>
                                <TextField className={classStyles.promoInput} variant="standard" InputProps={{
                                    disableUnderline: true,
                                }} onChange={(e:any) =>props.onPromoCodeChange(e.target.value) } value={props.promoCode} data-test-id='promo-code-id'/>
                                <Button className={classStyles.applyBtn} onClick={props.applyPromoCode} disabled={!props.promoCode} data-test-id='promo-apply'>Apply</Button>
                            </Box>

                        </CardContent>
                    </Card>
                    <Typography style={{color:props.promoCodeDetail.isValid ? 'green':'red', marginTop:'16px'}}>{props.promoCodeDetail.msg}</Typography>

                </Box>
                
                <Button
                    onClick={props.subscribeUserAPI}
                    data-test-id='submit'
                    disabled={props.activeId === 0}
                    className={classStyles.button}
                    fullWidth
                    endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>}
                >Payment
                </Button>
            </Box>

        </Grid>
    </Grid>
        // Customizable Area End
    )
}
// Customizable Area Start

// Customizable Area End
