import { CometChat } from "@cometchat/chat-sdk-javascript";
import {
	CometChatUIKit,
	MessageListStyle,
	DateStyle,
	CometChatUIKitConstants,
	CometChatMessageTemplate,
} from "@cometchat/chat-uikit-react";
import moment from "moment";
import { Conversation } from "../../blocks/chat/src/MessageItemController.web";
import uuid from "uuid"
import { IGroupsPermissionSettings } from "../../blocks/groups/src/GroupsController";
import CometChatBubbleViewTemplate from "./CometChatBubbleViewTemplate";
import React, { Fragment } from "react";
import CometChatBubbleViewAlert from "./CometChatBubbleViewAlert";

type TDefinedTemplate = Pick<IBubbleViewRendererProps, 'userInfo' | 'checked' | 'isBlockedByMe' | 'onOpenModalTotalReaction'>;

interface IBubbleViewRendererProps {
	message: CometChat.BaseMessage;
	typeTemplate: string;
	categories: string;
	userInfo: CometChat.User | null;
	checked?: boolean;
	isBlockedByMe?: boolean;
	onOpenModalTotalReaction?: (listReaction: IReaction[]) => void;
}

export interface IMetadataConversation {
	username?: string;
	adminApproveNewMembers?: boolean,
	membersPermissions?: {
		addMembers: boolean,
		editSettings: boolean,
		sendMessages: boolean
	};
	user_subscription?: string;
	user_ownership?: boolean
}

export interface IConversation {
	avatar?: string;
	name: string;
	metadata: IMetadataConversation;
	icon?: string;
	scope?: string;
	guid?: string;
	blockedByMe?: boolean;
	uid?: string;
}

export interface ReactionItem {
	reaction: string;
	uid: string;
}

export interface ReactionObject {
	reaction: string;
	count: number;
	reactedByMe: boolean;
}

interface IReactedBy {
	hasBlockedMe: boolean;
	blockedByMe: boolean;
	uid: string;
	name: string;
	avatar?: string; // Optional if not all users have an avatar
}

export interface IReaction {
	id: string;
	messageId: string;
	reaction: string;
	uid: string;
	reactedAt: number;
	reactedBy: IReactedBy;
}

export interface ICustomMessageData {
	message?: string,
	type: CUSTOM_MESSAGE_DATA_TYPE
}

export enum TYPE_CONVERSATION {
	GROUP = "group",
	USER = "user"
}

export enum CUSTOM_MESSAGE_TYPE {
	CUSTOM_ACTION = "action",
}

export enum CUSTOM_MESSAGE_DATA_TYPE {
	BLOCK = "block",
	UNBLOCK = "unblock"
}

export type TTypeConversation = "group" | "user"

export const updateConversationsWhenHasNewMessage = (newMessage: CometChat.TextMessage, prevConversations: Conversation[]) => {
	const isUserMessage = newMessage.getReceiverType() === CometChat.RECEIVER_TYPE.USER
	const conversationId = isUserMessage ? newMessage.getSender().getUid() : newMessage.getReceiverId()
	let updatedConversations = [...prevConversations];
	const conversationIndex = updatedConversations.findIndex(conversation => conversation.id === conversationId);

	if (conversationIndex !== -1) {
		const updatedConversation = updatedConversations[conversationIndex];
		updatedConversation.lastMessage = getMessageContent(newMessage);
		updatedConversation.lastMessageTimestamp = moment.unix(newMessage.getSentAt()).format('HH:mm');
		updatedConversation.unreadMessageCount += 1;

		updatedConversations.splice(conversationIndex, 1);
		updatedConversations.unshift(updatedConversation);
	} else {
		const newConversation: Conversation = {
			id: conversationId,
			type: isUserMessage ? 'USER' : 'GROUP',
			name: isUserMessage ? newMessage.getSender().getName() : newMessage.getReceiver().getName(),
			avatar: isUserMessage ? newMessage.getSender().getAvatar() : '',
			lastMessage: getMessageContent(newMessage),
			lastMessageTimestamp: moment.unix(newMessage.getSentAt()).format('HH:mm'),
			unreadMessageCount: 1,
		};
		updatedConversations.unshift(newConversation);
	}

	return updatedConversations
};

const updateChatWhenSendUserMessage = (
	message: CometChat.TextMessage,
	receiver: CometChat.User,
	prevConversations: Conversation[]
) => {
	let updatedConversations = [...prevConversations];
	const conversationIndex = updatedConversations.findIndex(conversation => conversation.id === message.getReceiverId());
	if (conversationIndex !== -1) {
		const updatedConversation = updatedConversations[conversationIndex];
		updatedConversation.lastMessage = getMessageContent(message);
		updatedConversation.lastMessageTimestamp = moment.unix(message.getSentAt()).format('HH:mm');
		updatedConversation.unreadMessageCount = 0;

		updatedConversations.splice(conversationIndex, 1);
		updatedConversations.unshift(updatedConversation);
	} else {
		const newConversation: Conversation = {
			id: receiver.getUid(),
			type: 'USER',
			name: receiver.getName(),
			avatar: receiver.getAvatar(),
			lastMessage: getMessageContent(message),
			lastMessageTimestamp: moment.unix(message.getSentAt()).format('HH:mm'),
			unreadMessageCount: 0,
		};
		updatedConversations.unshift(newConversation);
	}
	return updatedConversations
}

const updateChatWhenSendGroupMessage = (
	message: CometChat.TextMessage,
	receiver: CometChat.Group,
	prevConversations: Conversation[]
) => {
	let updatedConversations = [...prevConversations]
	const conversationIndex = updatedConversations.findIndex(conversation => conversation.id === message.getReceiverId())
	if (conversationIndex !== -1) {
		const updatedConversation = updatedConversations[conversationIndex]
		updatedConversation.unreadMessageCount = 0
		updatedConversation.lastMessageTimestamp = moment.unix(message.getSentAt()).format('HH:mm')
		updatedConversation.lastMessage = getMessageContent(message);

		updatedConversations.splice(conversationIndex, 1)
		updatedConversations.unshift(updatedConversation)
	} else {
		const newConversation: Conversation = {
			id: receiver.getGuid(),
			type: 'GROUP',
			name: receiver.getName(),
			avatar: receiver.getIcon(),
			unreadMessageCount: 0,
			lastMessageTimestamp: moment.unix(message.getSentAt()).format('HH:mm'),
			lastMessage: getMessageContent(message),
		};
		updatedConversations.unshift(newConversation);
	}

	return updatedConversations
}

export const updateMessageTemplates = ({
	userInfo,
	checked,
	isBlockedByMe,
	onOpenModalTotalReaction
}: TDefinedTemplate) => {
	// Get all message templates
	let definedTemplates = CometChatUIKit.getDataSource().getAllMessageTemplates();

	// Define type templates to override
	const typeTemplatesOverride = [
		CometChatUIKitConstants.MessageTypes.text,
		CometChatUIKitConstants.MessageTypes.image,
		CometChatUIKitConstants.MessageTypes.file,
		CometChatUIKitConstants.MessageTypes.groupMember
	];

	// Define categories templates to override
	const categoriesTemplatesOverride = [
		CometChatUIKitConstants.MessageCategory.message,
		CometChatUIKitConstants.MessageCategory.action,
	];

	// Override existing templates
	typeTemplatesOverride.forEach(typeTemplate => {
		const messageTemplates = definedTemplates.find(
			(template) =>
				categoriesTemplatesOverride.includes(template.category) &&
				template.type === typeTemplate
		);

		if (!messageTemplates) return;

		const textTemplateObjectIndex = definedTemplates.indexOf(messageTemplates);

		messageTemplates.bubbleView = (message: CometChat.BaseMessage) =>
			getBubbleViewRenderer({
				message,
				typeTemplate,
				categories: messageTemplates.category,
				userInfo: userInfo,
				checked: checked,
				onOpenModalTotalReaction
			});

		if (textTemplateObjectIndex > -1) {
			definedTemplates.splice(textTemplateObjectIndex, 1, messageTemplates);
		}
	});

	// Add a custom message template
	let customMessageTemplate = new CometChatMessageTemplate({
		type: CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION,
		category: CometChatUIKitConstants.MessageCategory.custom,
		bubbleView: (message: CometChat.BaseMessage) =>
			getBubbleViewRenderer({
				message,
				typeTemplate: CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION,
				categories: CometChatUIKitConstants.MessageCategory.custom,
				userInfo: userInfo,
				checked: checked,
				isBlockedByMe
			}),
	});

	definedTemplates.push(customMessageTemplate);

	return definedTemplates;
}

export const getListUserFriendComet = async () => {
	let limit: number = 30;
	let usersRequest: CometChat.UsersRequest = new CometChat.UsersRequestBuilder()
		.setLimit(limit)
		.friendsOnly(true)
		.hideBlockedUsers(true)
		.build();
	const listUser = await usersRequest.fetchNext();
	return listUser
}

export const sendTextMessage = async (
	messageText: string,
	selectedConversation: CometChat.User | CometChat.Group | null,
	prevConversations: Conversation[]
) => {
	if (!selectedConversation) return

	let textMessage: CometChat.TextMessage
	let receiverID: string
	let updatedConversations: Conversation[] = []

	try {
		if (selectedConversation instanceof CometChat.User) {
			receiverID = selectedConversation.getUid()
			textMessage = new CometChat.TextMessage(receiverID, messageText, CometChat.RECEIVER_TYPE.USER)

			const receiver = await CometChat.getUser(receiverID)
			const messageData = await CometChatUIKit.sendTextMessage(textMessage) as unknown as CometChat.TextMessage;
			updatedConversations = updateChatWhenSendUserMessage(messageData, receiver, prevConversations);

		} else {
			receiverID = selectedConversation.getGuid()
			textMessage = new CometChat.TextMessage(receiverID, messageText, CometChat.RECEIVER_TYPE.GROUP)
			const receiver = await CometChat.getGroup(receiverID);
			const messageData = await CometChatUIKit.sendTextMessage(textMessage) as unknown as CometChat.TextMessage;
			updatedConversations = updateChatWhenSendGroupMessage(messageData, receiver, prevConversations);
		}
	} catch (error) {
		console.log('Send text message failed with error', error);
	}

	return updatedConversations
}

export const sendMediaMessage = async (
	file: File,
	selectedConversation: CometChat.User | CometChat.Group | null,
	prevConversations: Conversation[]
) => {
	if (!selectedConversation) return

	let receiverID: string
	let updatedConversations: Conversation[] = []

	const isTypeImage = file.type.startsWith('image/');
	const mediaMessageType = isTypeImage ? CometChat.MESSAGE_TYPE.IMAGE : CometChat.MESSAGE_TYPE.FILE;
	let receiverType = CometChat.RECEIVER_TYPE.USER;
	try {
		if (selectedConversation instanceof CometChat.User) {
			receiverID = selectedConversation.getUid()
			const mediaMessage = new CometChat.MediaMessage(
				receiverID,
				file,
				mediaMessageType,
				receiverType
			);
			const receiver = await CometChat.getUser(receiverID)
			const messageData = await CometChatUIKit.sendMediaMessage(mediaMessage) as unknown as CometChat.TextMessage;
			updatedConversations = updateChatWhenSendUserMessage(messageData, receiver, prevConversations);
		} else {
			receiverID = selectedConversation.getGuid();
			receiverType = CometChat.RECEIVER_TYPE.GROUP;
			const mediaMessage = new CometChat.MediaMessage(
				receiverID,
				file,
				mediaMessageType,
				receiverType
			);
			const receiver = await CometChat.getGroup(receiverID);
			const messageData = await CometChatUIKit.sendMediaMessage(mediaMessage) as unknown as CometChat.TextMessage;
			updatedConversations = updateChatWhenSendGroupMessage(messageData, receiver, prevConversations);
		}
	} catch (error) {
		console.log('Send media message failed with error', error);
	}

	return updatedConversations
}

export const sendCustomMessage = async (receiverID: string, customData: ICustomMessageData, customType: CUSTOM_MESSAGE_TYPE) => {
	const receiverType = CometChat.RECEIVER_TYPE.USER;
	let customMessage: CometChat.CustomMessage = new CometChat.CustomMessage(
		receiverID,
		receiverType,
		customType,
		customData
	);
	await CometChatUIKit.sendCustomMessage(customMessage);
}

export const startTypingMessage = async (
	selectedConversation: CometChat.User | CometChat.Group | null,
) => {
	if (!selectedConversation) return;

	let receiverType: string = CometChat.RECEIVER_TYPE.USER;
	let receiverId: string

	try {
		if (selectedConversation instanceof CometChat.User) {
			receiverId = selectedConversation.getUid()
		} else {
			receiverId = selectedConversation.getGuid()
		}
		let typingNotification: CometChat.TypingIndicator = new CometChat.TypingIndicator(receiverId, receiverType);
		CometChat.startTyping(typingNotification);

	} catch (error) {
		console.log('Start typing text message failed with error', error);
	}
}

export const stopTypingMessage = async (
	selectedConversation: CometChat.User | CometChat.Group | null,
) => {
	if (!selectedConversation) return;

	let receiverType: string = CometChat.RECEIVER_TYPE.USER;
	let receiverId: string

	try {
		if (selectedConversation instanceof CometChat.User) {
			receiverId = selectedConversation.getUid()
		} else {
			receiverId = selectedConversation.getGuid()
		}
		let typingNotification: CometChat.TypingIndicator = new CometChat.TypingIndicator(receiverId, receiverType);
		CometChat.endTyping(typingNotification);

	} catch (error) {
		console.log('Stop typing text message failed with error', error);
	}
}

export const addReactionToMessage = async (messageId: number, emoji: string) => {
	try {
		await CometChat.addReaction(messageId, emoji);
	}
	catch (err) {
		console.error("Send reaction fail")
	}
}

export const removeReactionToMessage = async (messageId: number, emoji: string) => {
	try {
		await CometChat.removeReaction(messageId, emoji);
	}
	catch (err) {
		console.error("Send reaction fail")
	}
}

export const fetchConversations = async () => {
	const limit = 30;
	const conversationRequest = new CometChat.ConversationsRequestBuilder()
		.setLimit(limit)
		.includeBlockedUsers(true)
		.build();

	try {
		const conversationList: CometChat.Conversation[] = await conversationRequest.fetchNext();
		if (!conversationList.length)
			return [];
		const formattedConversations: Conversation[] = conversationList.map((conversation: CometChat.Conversation) => {
			const lastMessage = conversation.getLastMessage();
			const lastMessageText = getMessageContent(lastMessage)
			const lastMessageTime = lastMessage ? moment.unix(lastMessage.getSentAt()).format('HH:mm') : ''
			const conversationtype = conversation.getConversationType()
			const isUser = conversationtype === TYPE_CONVERSATION.USER;

			if (isUser) {
				const user = conversation.getConversationWith() as CometChat.User;
				return {
					id: user.getUid(),
					type: 'USER',
					name: user.getName(),
					avatar: user.getAvatar(),
					lastMessage: lastMessageText,
					lastMessageTimestamp: lastMessageTime,
					unreadMessageCount: conversation.getUnreadMessageCount(),
				};
			} else {
				const group = conversation.getConversationWith() as CometChat.Group
				return {
					id: group.getGuid(),
					type: 'GROUP',
					name: group.getName(),
					avatar: group.getIcon(),
					lastMessage: lastMessageText,
					lastMessageTimestamp: lastMessageTime,
					unreadMessageCount: conversation.getUnreadMessageCount(),
				}
			}
		});

		return formattedConversations;
	} catch (error) {
		console.log("Conversations list fetching failed with error:", error);
		return [];
	}
};

export const fetchUsers = async (listReceiverUid: string[]) => {
	const limit = 30;
	const usersRequest = new CometChat.UsersRequestBuilder()
		.setLimit(limit)
		.setUIDs(listReceiverUid)
		.build();
	try {
		const response = await usersRequest.fetchNext();
		return response
	} catch (error) {
		console.log("User list fetching failed with error:", error);
		return [];
	}
};

export const fetchListReactionByMessage = async (messageId: number) => {
	try {
		let limit = 10;
		let reactionRequest = new CometChat.ReactionsRequestBuilder()
			.setMessageId(messageId)
			.setLimit(limit)
			.build();

		const listReaction = await reactionRequest.fetchNext();
		return listReaction as unknown as ReactionItem[];
	} catch (err) {
		console.error("Fetch list reaction by message id fail", err)
		return [];
	}
}

export const getMessageContent = (message: CometChat.TextMessage | CometChat.MediaMessage | CometChat.CustomMessage) => {
	if (!message) return "New conversation"
	if (message.getReceiverType() === CometChat.RECEIVER_TYPE.GROUP && message.getCategory() === CometChat.CATEGORY_ACTION) {
		const castMessage = message as unknown as { message: string };
		return castMessage.message
	}
	const mediaFileType = message.getData().type;
	if (message instanceof CometChat.TextMessage) {
		return message.getText()
	}
	if (message instanceof CometChat.MediaMessage) {
		return `Sent an ${mediaFileType}`
	}
	if (message instanceof CometChat.CustomMessage) {
		const { type } = message.getCustomData() as ICustomMessageData;
		if (type === CUSTOM_MESSAGE_DATA_TYPE.BLOCK || type === CUSTOM_MESSAGE_DATA_TYPE.UNBLOCK)
			return "Have a new action";
		return "Have an new message"
	}
	return "Have an new message"
}

export const getMessageFromConversation = async (idTypeConversation: string, typeConversation: TTypeConversation) => {
	let limit = 30;
	let messagesRequest;
	try {
		if (typeConversation.toLowerCase() === TYPE_CONVERSATION.USER) {
			messagesRequest = new CometChat.MessagesRequestBuilder()
				.setUID(idTypeConversation)
				.setLimit(limit)
				.build();
		} else {
			messagesRequest = new CometChat.MessagesRequestBuilder()
				.setGUID(idTypeConversation)
				.setLimit(limit)
				.build();
		}

		const listMessage = await messagesRequest.fetchPrevious();
		return listMessage;
	} catch (error) {
		console.error("Message fetching failed with error:", error);
		return [];
	}
}

export const getMessageRequestBuilder = () => {
	let categories = CometChatUIKit.getDataSource().getAllMessageCategories();
	categories.push(CometChatUIKitConstants.MessageCategory.custom);

	let types = CometChatUIKit.getDataSource().getAllMessageTypes();
	types.push(CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION);

	return new CometChat.MessagesRequestBuilder().setCategories(categories).setTypes(types).hideReplies(false).setLimit(30);
}

export const getConversationAvatar = (conversation: CometChat.User | CometChat.Group) => {
	if (conversation instanceof CometChat.User) {
		return conversation.getAvatar()
	} else {
		return conversation.getIcon()
	}
}

export const getBubbleViewRenderer = ({
	message,
	typeTemplate,
	categories,
	userInfo,
	checked,
	isBlockedByMe = false,
	onOpenModalTotalReaction
}: IBubbleViewRendererProps) => {
	const sender = message?.getSender();
	const messageId = message?.getId();

	if (!sender || !messageId) {
		return <Fragment />;
	}

	if (categories === CometChatUIKitConstants.MessageCategory.custom && typeTemplate === CUSTOM_MESSAGE_TYPE.CUSTOM_ACTION)
		return <CometChatBubbleViewAlert
			isBlockedByMe={isBlockedByMe}
			message={message}
			userInfo={userInfo}
			checked={checked}
		/>;

	return <CometChatBubbleViewTemplate
		message={message}
		typeTemplate={typeTemplate}
		categories={categories}
		userInfo={userInfo}
		checked={checked}
		onOpenModalTotalReaction={onOpenModalTotalReaction}
	/>;
}

export const createGroupChat = async ({
	listMemberUid,
	groupName,
	iconGroup,
	onClose,
	metadata
}: {
	listMemberUid: string[],
	groupName: string,
	iconGroup: string | undefined,
	onClose: () => void;
	metadata: IGroupsPermissionSettings
}) => {
	try {
		const members: Array<CometChat.GroupMember> = listMemberUid.map(uid => new CometChat.GroupMember(uid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT))
		let GUID: string = uuid.v4().toString();
		let groupType: string = CometChat.GROUP_TYPE.PUBLIC;
		let groupWithMember = new CometChat.Group(GUID, groupName, groupType, undefined, iconGroup);
		var groupWithoutMember = new CometChat.Group(GUID, groupName, groupType, undefined, iconGroup);
		let banMembers: Array<string> = [];

		groupWithMember.setMetadata(metadata);
		groupWithoutMember.setMetadata(metadata);

		if (!members.length) {
			await CometChat.createGroup(groupWithoutMember)
		} else await CometChat.createGroupWithMembers(groupWithMember, members, banMembers);

		onClose();
	} catch (err) {
		console.error("Create group be error", err)
	}

}

export const blockUsers = async (usersList: string[]) => {
	try {
		await CometChat.blockUsers(usersList);
	} catch (err) {
		console.error("Block user failing", err)
	}
}

export const unblockUsers = async (usersList: string[]) => {
	try {
		await CometChat.unblockUsers(usersList);
	} catch (err) {
		console.error("Block user failing", err)
	}
}

export const leaveGroup = async (guid: string) => {
	try {
		await CometChat.leaveGroup(guid);
	} catch (err) {
		console.error("Leaver a group fail", err)
	}
}

export const messageListStyle = new MessageListStyle({
	background: "transparent",
	height: "100%",
	width: "100%",
	loadingIconTint: "#8a33f0",
});

export const dateStyle = new DateStyle({
	textColor: '#757575',
	textFont: "500 13px Manrope",
	background: '#7575751F',
	borderRadius: "6px",
})