// Customizable Area Start
import React from "react";

import GroupsPermissionController, {
    Props,
} from "./GroupsPermissionController";
import { Box, IconButton, styled, Typography } from "@material-ui/core";
import { IOSSwitch } from "../../../components/src/ModifiedComponents.web";
import { groupMemberBlackIcon, groupMemberIcon, rightBlackIcon, rightIcon } from "./assets";


export class GroupsPermission extends GroupsPermissionController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <GroupsPermissionStyled>
                <Box className="settingPermissionGroupContainer">
                    {this.listSettings.map(({ role, settings, footerText, keyValue: roleKeyValue = "" }) => (
                        <Box key={role} className="settingPermissionGroup">
                            <Box className="settingPermissionGroupLabel">
                                <Typography className={`settingPermissionLabel ${this.props.checked && "lightTheme__text"}`} component="p">
                                    {role.charAt(0).toUpperCase() + role.slice(1)} can
                                </Typography>
                            </Box>
                            {settings.map(({ icon, title, subDescription, keyValue: settingKeyValue }) => (
                                <Box className="settingPermissionGroupContent" key={title}>
                                    <Box className="settingPermissionIcon">
                                        <img alt="icon" className="iconSetting" width={24} height={24} src={icon} />
                                    </Box>
                                    <Box className="settingPermissionGroup-title">
                                        <Typography className={`settingPermission-title ${this.props.checked && "lightTheme__text"}`} component="h5">
                                            {title}
                                        </Typography>
                                        {subDescription && (
                                            <Typography className={`settingPermission-subText ${this.props.checked && "lightTheme__text"}`} component="p">
                                                {subDescription}
                                            </Typography>
                                        )}
                                    </Box>
                                    <IOSSwitch
                                        data-test-id="switchPermissionSetting"
                                        onChange={this.handleSwitchChange(roleKeyValue, settingKeyValue)}
                                        checked={
                                            roleKeyValue
                                                ? (this.state.settingsState[roleKeyValue] as { [key: string]: boolean })[settingKeyValue]
                                                : this.state.settingsState[settingKeyValue]
                                        }
                                    />
                                </Box>
                            ))}
                            <Box className="settingPermissionGroupFooter">
                                <Typography className="settingPermission-subText" component="p">
                                    {footerText}
                                </Typography>
                                <Typography className="settingPermission-subText spanText" component="span">
                                    {role === "admin" && "Learn more"}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="settingPermissionGroup" style={{ marginTop: 10 }}>
                    <Box className="settingPermissionGroupContent" >
                        <img alt="icon" className="iconSetting" width={24} height={24} src={this.props.checked ? groupMemberBlackIcon : groupMemberIcon} />
                        <Box className="settingPermissionGroup-title">
                            <Typography className={`settingPermission-title ${this.props.checked && "lightTheme__text"}`} component="h5">
                                Group Members
                            </Typography>
                        </Box>
                        <IconButton className="backArrowButton">
                            <img width={24} height={24} alt="i" src={this.props.checked ? rightBlackIcon : rightIcon} />
                        </IconButton>
                    </Box>
                </Box>
            </GroupsPermissionStyled>
        );
    }
}

const GroupsPermissionStyled = styled(Box)({
    "& .lightTheme__text": {
        color: "#222222!important"
    },
    "& .settingPermissionGroupContainer": {
        display: "flex",
        flexDirection: "column",
        rowGap: 30,
    },
    "& .settingPermissionGroup": {
        display: "flex",
        flexDirection: "column",
        rowGap: 20,
        "& .settingPermission-subText": {
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 12,
            color: "#6B6B6B"
        },
        "& .settingPermissionGroupFooter": {
            marginTop: "-10px",
            "& .settingPermission-subText.spanText": {
                backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                color: "transparent",
                backgroundClip: "text"
            },
        },
        "& .settingPermissionGroupLabel": {
            "& .settingPermissionLabel": {
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontWeight: 600,
                fontSize: 16
            }
        },
        "& .settingPermissionGroupContent": {
            display: "flex",
            columnGap: 4,
            alignItems: "center",
            "& .settingPermissionIcon": {
            },
            "& .settingPermissionGroup-title": {
                flex: 1,
                "& .settingPermission-title": {
                    color: "#FFFFFF",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    fontSize: "16px"
                },
            }
        }
    }
})

export default GroupsPermission;

// Customizable Area End
