// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createGroupChat, fetchUsers, getListUserFriendComet } from "../../../components/src/CometChat";
import { createRef, RefObject } from "react";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
import { EmojiData } from "emoji-mart";

export const configJSON = require("./config");

export type TTabGroupScreen = "create" | "permission"

interface ICheckedContacts {
  [key: string]: boolean
}

interface IMembersPermissions {
  addMembers: boolean;
  editSettings: boolean;
  sendMessages: boolean;
}

export interface IGroupsPermissionSettings {
  adminApproveNewMembers: boolean;
  disapearingMessages: number;
  membersPermissions: IMembersPermissions;
}

export interface Props {
  classes?: any
  onClose: () => void;
  checked?: boolean;
}

interface S {
  token: string;
  listContact: CometChat.User[];
  listFrequentlyContact: CometChat.User[];
  checkedContacts: ICheckedContacts;
  emojiAnchorEl: null | HTMLElement;
  createGroupName: string;
  createGroupIcon: string;
  selectedFile: File | null;
  currentGroupScreen: TTabGroupScreen;
  groupsPermissionSetting: IGroupsPermissionSettings;
  isOpenModalDisappearing: boolean;
}

interface SS {
  id: any;
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  fileInputUploadImg: RefObject<HTMLInputElement>;
  uploadImageCallId: string = "";
  getMessageCountId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      listContact: [],
      listFrequentlyContact: [],
      checkedContacts: {},
      createGroupName: "",
      createGroupIcon: "",
      selectedFile: null,
      emojiAnchorEl: null,
      currentGroupScreen: "create",
      groupsPermissionSetting: {
        "adminApproveNewMembers": false,
        "disapearingMessages": 0,
        "membersPermissions": {
          "addMembers": true,
          "editSettings": true,
          "sendMessages": true
        }
      },
      isOpenModalDisappearing: false
    };

    this.fileInputUploadImg = createRef()

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const token = await getStorageData("authToken");
    const listUsers = await getListUserFriendComet();
    await this.getFrequentlyContact();
    this.setState({ listContact: listUsers, token })
  }

  async componentWillUnmount() {
    // Revoke the data URI to free up memory
    if (this.state.createGroupIcon) {
      URL.revokeObjectURL(this.state.createGroupIcon);
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadImageCallId != null &&
      this.uploadImageCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson?.data) {
        const { url } = responseJson?.data?.attributes;
        this.createGroup(url);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getMessageCountId != null &&
      this.getMessageCountId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson?.data) {
        const listMessageCount = responseJson?.data
        const mappingFrequentlyContact = listMessageCount.map((contact: any) => {
          const receiverId = contact?.receiver_uid;
          return receiverId
        })

        const listFrequentlyContact = await fetchUsers(mappingFrequentlyContact)
        this.setState({ listFrequentlyContact })
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  getFrequentlyContact = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMessageCountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.messageCountApiEndPoint}?per_page=5`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleChangeGroupScreen = (tab: TTabGroupScreen) => {
    this.setState({ currentGroupScreen: tab })
  }

  handleChangeGroupName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const groupName = event.target.value;
    this.setState({ createGroupName: groupName })
  }

  handleCheckboxChange = (contactId: string) => {
    this.setState((prevState) => ({
      checkedContacts: {
        ...prevState.checkedContacts,
        [contactId]: !prevState.checkedContacts[contactId]
      }
    }));
  };

  handleCheckedItemClick = (contactId: string) => {
    this.setState((prevState) => {
      const newCheckedContacts = { ...prevState.checkedContacts };
      delete newCheckedContacts[contactId];

      return { checkedContacts: newCheckedContacts };
    });
  };

  handleImageClick = () => {
    if (this.fileInputUploadImg.current) {
      this.fileInputUploadImg.current?.click();
    }
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const imageUrl = URL.createObjectURL(files[0]);
      this.setState({ createGroupIcon: imageUrl, selectedFile: files[0] });
    }
  };

  nextCreateGroup = async () => {
    if (!this.state.selectedFile) {
      this.createGroup()
      return;
    };

    await this.uploadImageFile(this.state.selectedFile);
  }

  uploadImageFile = async (imageFile: File) => {
    const header = {
      token: this.state.token,
    };
    const form = new FormData();
    form.append("media", imageFile);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_bulk_uploading//attachments"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createGroup = (iconGroup?: string) => {
    const listSelectedMember = Object.keys(this.state.checkedContacts)

    createGroupChat({
      groupName: this.state.createGroupName,
      iconGroup,
      listMemberUid: listSelectedMember,
      onClose: this.props.onClose,
      metadata: this.state.groupsPermissionSetting
    },);
  }

  handleChangeGroupsPermissionSetting = (groupsPermissionSetting: IGroupsPermissionSettings) => {
    this.setState({ groupsPermissionSetting })
  }

  handleCloseEmoji = () => {
    this.setState({ emojiAnchorEl: null });
  }

  handleClickEmoji = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ emojiAnchorEl: event.currentTarget });
  }

  handleSelectEmoji = (emoji: EmojiData) => {
    this.setState({ createGroupName: this.state.createGroupName + (emoji as any).native })
  }

  handleToggleModalDisappearing = (currentBool: boolean) => {
    this.setState({ isOpenModalDisappearing: !currentBool });
  }

  handleSubmitDisappearingMessage = (value: string) => {
    const { groupsPermissionSetting } = this.state;
    const updateGroupsPermissionSetting = { ...groupsPermissionSetting };
    updateGroupsPermissionSetting["disapearingMessages"] = Number(value);
    this.setState({ groupsPermissionSetting: updateGroupsPermissionSetting, isOpenModalDisappearing: false })
  }
}

// Customizable Area End
