import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { verifyIcon, verifyOrange, verifyYellow } from "../../blocks/activityfeed/src/assests";
import {EventEmitter} from 'fbemitter'

export type TAny = any;

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}
export function sendAPIRequest(endpoint: string, params?: RequestParams) {

  const { method = "GET", headers, body } = params || {}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  const callId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  if (body)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body?.append ? body : JSON.stringify(body)
    )

  if (headers)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  return callId
}

export const convertMentionHashtagText = (input: string): string => {
  return input.replace(/@\*(.*?)\*|\@\[(#.*?)\]\(\d+\)/g, (match: string, p1: string, p2: string) => {
    if (p1 !== undefined) {
      return `${p1}`; // Convert @*mention* to @mention
    } else if (p2 !== undefined) {
      return p2; // Convert @[#hash_tag](5) to #hash_tag
    }
    return match; // Return the original match if no condition is met
  });
}

export const downloadGifFromUrl = async (gifUrl: string, gifName: string) => {
  try {
    const response = await fetch(gifUrl);
    const blob = await response.blob();
    const file = new File([blob], gifName, { type: 'image/gif' });
    return file
  } catch (error) {
    console.error('Error downloading GIF', error);
  }
};

export const removeStorageData = async (key: string) => {
  try {
      localStorage.removeItem(key);
      console.log(`Removed item with key: ${key}`);
  } catch (error) {
      console.error(`Error removing item with key: ${key}`, error);
  }
};

export const highlightText = (body: any, highlights: any) => {
  const combinedHighlights = [...highlights].sort((a, b) => a.indices[0] - b.indices[0]);

  let highlightedBody = '';
  let currentIndex = 0;

  combinedHighlights.forEach(highlight => {
    const start = highlight.indices[0];
    const end = highlight.indices[1];

    highlightedBody += body.substring(currentIndex, start);

    highlightedBody += `<span style="color: ${"#8429Ef"};" >${body.substring(start, end)}</span>`;
    currentIndex = end;
  });

  highlightedBody += body.substring(currentIndex);

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const outputString = highlightedBody.replace(urlRegex, (url) => {
    return `<a href=${url} target="_blank" style="color: ${"#8429Ef"};">${url}</a>`;
  });

  return outputString;
};

export const getBorderRadiusClass = (index: number, length: number, classes: any) => {
  if (length === 1) return classes.borderRadius;
  const borderClasses = [];
  if (index === 0) borderClasses.push(classes.topLeftRadius);
  if (index === 1) {
    if (length === 2) {
      borderClasses.push(classes.topRightRadius, classes.bottomRightRadius);
    } else {
      borderClasses.push(classes.topRightRadius);
    }
  }
  if (index === length - 2) {
    if (length === 3) {
      borderClasses.push(classes.bottomRightRadius);
    } else {
      borderClasses.push(classes.bottomLeftRadius);
    }
  }
  if (index === length - 1) {
    if (length === 3) {
      borderClasses.push(classes.bottomLeftRadius);
    } else {
      borderClasses.push(classes.bottomRightRadius);
    }
  }
  return borderClasses.join(' ');
};

export const verifyImg = (userSubscription: string) => {
  switch (userSubscription) {
    case 'I am a business':
      return verifyYellow
    case 'I am a news organization':
      return verifyOrange
    default:
      return verifyIcon
  }
}

export function convertTimestampToTime(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const hours: string = date.getUTCHours().toString().padStart(2, '0');
  const minutes: string = date.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export const handleConditionFunction = (condition: TAny, trueCondition: TAny, falseCondtion: TAny) => {
  return condition ? trueCondition : falseCondtion
}

export const emitterEvents = new EventEmitter()