// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Typography } from "@material-ui/core";
import PostBodyController, { Props } from "./PostBodyController.web";
import ReactPlayer from "react-player";
import { getBorderRadiusClass, highlightText } from "../../../components/src/utils";
import ParentPostUi from "../../repost/src/ParentPostUi.web";
import { Post } from "./ActivityFeedController";
import YoutubeVideo from "../../../components/src/Posts/YoutubeVideo";

export class PostBody extends PostBodyController {
  constructor(props: Props) {
    super(props);
  }

  mediaRender = (classes: any, src: any, index: number, length: number) => {
    const borderRadiusClass = getBorderRadiusClass(index, length, classes);
    if (src.content_type.startsWith("image")) {
      return (
        <img 
          key={src.id} 
          src={src.thumbnail_url ?? src.url} 
          loading="lazy"
          alt="post" 
          style={{ height: length > 2 ? "165px" : "264px" }}
          className={`${classes.postImageStyle} ${borderRadiusClass}`} 
          {...({} as any)}
        />
      )
    } else if (src.content_type.startsWith("video")) {
      return <div
        key={src.id}
        className={`${classes.postImageStyle} ${borderRadiusClass}`}
        style={{ height: length > 2 ? "165px" : "264px" }}
      >
        <ReactPlayer
          url={src.url}
          controls={true}
          loading="lazy"
          className={`${classes.reactPlayerStyle} ${borderRadiusClass}`}
        />
      </div>
    } else if (src.content_type.startsWith("audio")) {
      return <audio key={src.id} src={src.url} controls >
        Your browser does not support the audio element.
      </audio>
    } else if (src.content_type === 'application/pdf') {
      return (
        <a key={src.id} href={src.url} target="_blank" rel="noopener noreferrer">
          <img 
            src={src.thumbnail_url} 
            loading="lazy"
            alt="post" 
            style={{ height: length > 2 ? "165px" : "264px" }}
            className={`${classes.postImageStyle} ${borderRadiusClass}`} 
            {...({} as any)}
          />
        </a>
      )
    }
  }

  renderParentPost = () => {
    const { is_repost, parent_post, body } = this.props.postData.attributes
    if (is_repost && parent_post && body) {
      const customParentPost: Post = {
        id: parent_post.id.toString(),
        type: "post",
        attributes: parent_post
      }
      
      return (
        <ParentPostUi postData={customParentPost} checked={this.props.checked} />
      )
    }
  }

  render() {
    const { classes, postData, checked } = this.props;
    const { is_repost, parent_post, body, media_links } = postData.attributes
    const renderPost = is_repost && parent_post && !body ? parent_post : postData.attributes

    const highlights = [...renderPost.body_mentions, ...renderPost.hash_tags]
    const highlightedBody = (renderPost.body && highlightText(renderPost.body, highlights)) || ''

    return (
      <Box className={classes.postBody}>
        <Typography
          className={classes.postBodyContain}
          style={{ color: checked ? '#222222' : '#FFF' }}
        >
          {highlightedBody.split('\r\n').map((line, index) => (
            <React.Fragment key={index}>
              <Typography
                className={classes.postBodyContain}
                dangerouslySetInnerHTML={{ __html: line }}
              />
              <br style={{ lineHeight: 0 }} />
            </React.Fragment>
          ))}
        </Typography>

        {media_links.length > 0 && renderPost.media.length === 0 &&  (
          <YoutubeVideo youtubeUrl={media_links[media_links.length - 1].url} />
        )}

        <Box className={renderPost.media.length > 1 ? classes.feedmultiImages : classes.feedImages}>
          {renderPost.media.map((src: any, index: number) => (
            this.mediaRender(classes, src, index, renderPost.media.length)
          ))}
        </Box>
        {renderPost.media.length > 0 && renderPost.media_mentions.length > 0 && (
          <Box style={{ marginTop: '-14px' }}>
            <Typography className={classes.mentionMediaText}>
              {this.generateMentionMediaText(renderPost.media_mentions)}
            </Typography>
          </Box>
        )}
        {this.renderParentPost()}
      </Box>
    )
  }
}

const styles = createStyles({
  postBody: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "20px"
  },
  postBodyContain: {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    paddingRight: "24px"
  },
  feedmultiImages: {
    display: "grid" as "grid",
    gap: "10px",
    gridTemplateColumns: '1fr 1fr',
  },
  feedImages: {
    display: "grid" as "grid",
    gap: "10px",
  },
  mentionMediaText: {
    display: 'inline-block',
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '12px',
    color: '#FFF',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  borderRadius: {
    borderRadius: "16px",
  },
  topLeftRadius: {
    borderTopLeftRadius: "16px",
  },
  topRightRadius: {
    borderTopRightRadius: "16px",
  },
  bottomLeftRadius: {
    borderBottomLeftRadius: "16px",
  },
  bottomRightRadius: {
    borderBottomRightRadius: "16px",
  },
  postImageStyle: {
    width: "100%",
  },
  reactPlayerStyle: {
    width: "100% !important",
    height: "100%  !important",
    "& :first-child ": {
      objectFit: "fill !important",
      borderRadius: "16px"
    }
  },
});

export default withStyles(styles)(PostBody);
// Customizable Area End