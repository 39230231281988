import React from "react";
import { Box, styled } from "@material-ui/core";
import { LIST_REACTION } from './CometChatBubbleViewListReaction';

interface IPropCometChatBubbleViewReaction {
    messageId: number;
    isMouseOver: boolean;
    onAddReactionToMessage: (messageId: number, emoji: string) => Promise<void>;
}

export default function CometChatBubbleViewReaction({
    messageId,
    isMouseOver,
    onAddReactionToMessage,
}: IPropCometChatBubbleViewReaction) {
    return (
        <CometChatBubbleViewReactionStyled>
            {
                isMouseOver &&
                <Box className="listReactionContainer">
                    <ReactionListComponent messageId={messageId} onAddReactionToMessage={onAddReactionToMessage} />
                </Box>
            }
        </CometChatBubbleViewReactionStyled>
    )
}

const ReactionListComponent = ({ messageId, onAddReactionToMessage }: { messageId: number, onAddReactionToMessage: (messageId: number, emoji: string) => Promise<void> }) => {
    return (<ReactionListComponentStyled>
        {
            LIST_REACTION.map(reaction => {
                return <Box key={reaction.value} className="reactionItem" onClick={() => { onAddReactionToMessage(messageId, reaction.value) }}>
                    <img alt="r" src={reaction.icon} width={16} height={16} />
                </Box>
            })
        }
    </ReactionListComponentStyled>)

}

const CometChatBubbleViewReactionStyled = styled(Box)({
    "& .listReactionContainer": {
        
    }
});

const ReactionListComponentStyled = styled(Box)({
    background: "#222222",
    borderRadius: "10px",
    position: "absolute",
    width: "fit-content",
    top: "-30px",
    left: "40px",
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    padding: 8,
    cursor: "pointer",
    "& .reactionItem": {
        display: "flex",
        alignItems: "center",
        gap: 4,
    }
});